//
// ValidationError styles
//

.ValidationError {
    text-align: right;
    margin-top: 4px;
    font-size: .8rem;
    font-weight: bold;
    color: red;
    margin-bottom: 0;

    @media screen and (max-width: 768px) {
        .form-area {
            width: 350px;
        }
    }
}
