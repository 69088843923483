//
// Footer styles
//
#footer {
  background-color: $footer-color;
  padding: 70px 0;
  color: $white;

  .btn-white-border {
    width: 280px;
    padding: 15px;
    background-color: $footer-color;
    border: 1px solid $white;
    color: $white;
  }
  .btn-white {
    width: 280px;
    padding: 15px;
    background-color: $white;
    border: 1px solid $footer-color;
    color: $footer-color;
  }

  .footer-sp {
    display: block;
  }

  .inquiry-area {
    position: relative;
  }

  .button-top {
    position: fixed;
    right: 0;
    bottom: 130px;
    background-color: white;
    z-index: 1;
  }

  .footer-nav {
    margin-top: 70px;
    .nav-title {
      font-size: 18px;
      font-weight: 700;
    }
    .nav-list {
      margin: 0;
      padding: 0;
      .list-item {
        margin: 0;
        list-style: none;
        a {
          color: $white;
          font-size: 14px;
          text-decoration: none;
        }

        a.disabled {
          color: #a9a9a9;
          pointer-events: none;
        }
      }
    }
  }
  @media screen and (min-width: 769px){
    .footer-nav {
      margin-bottom: 110px;
      .nav-box {
        & + .nav-box {
          border-left: #97B9D2 solid 1px;
          padding-left: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 40px 0 70px 0;
    .footer-sp {
      display: flex;
      width: 50%;
    }
    .vcisoLogo {
      order: 2;
    }
    .footer-nav {
      margin-bottom: 60px;
    }
    .buttons-list {
      order: 1;
      padding-bottom: 60px;
      border-bottom: 1px solid $white;
      margin-bottom: 30px;
      .container {
        flex-wrap: wrap;
      }
      .btn {
        width: 100%;
      }
    }
  }
}
