//
// Header styles
//

.Header {
  #topnav {
    min-height: 50px;
    background-color: $white;

    .navbar {
      padding: 0;

      .navbar-nav .nav-link {
        color: #262121;
      }

      a:nth-child(1),
      a:nth-child(2) {
        // border-right: 1px solid #eee;
      }

      a.nav-link.disabled {
        color: #d3d3d3;
      }
    }
  }

  .navbar {
    .navbar-nav {
      .custom-nav.nav-link.disabled {
        svg * {
          stroke: #d3d3d3;
        }

        .nav-div {
          color: #d3d3d3;
        }
      }

      .custom-nav.nav-link.disabled.matching {
        svg * {
          fill: #d3d3d3;
          stroke: transparent;
        }
      }
    }
  }

  .navbar-brand {
    padding-left: 20px;
  }

  #nav-controls {
    background-color: #EEE;
    padding-right: 20px;
    display: flex;
    align-items: center;
  }

  .nav-buttons {
    flex-direction: row;
    align-items: center;
    height: 100%;
  }

  .header-menu {
    display: flex;
  }

  .registration-button,
  .login-button,
  .logout-button {
    display: none;
  }

  .icon-hamburger {
    z-index: 9998;
    position: absolute;
    top: 12px;
    right: -5px;
    width: 50px;
    height: 25px;
    float: right;
    text-align: center;
    cursor: pointer;
    display: none;

    span {
      display: block;
      position: absolute;
      top: 10px;
      left: 43%;
      width: 25px;
      height: 2px;
      margin-left: -25px;
      transform: rotate(0deg);
      background: #3f3f3f;
    }

    .top {
      transform: translateY(-5px);
      display: block;
      top: 12px;
    }

    .middle {
      transform: translateY(4px);
      display: block;
    }

    .bottom {
      transform: translateY(13px);
      display: block;
      bottom: 15px;
      top: auto;
    }
  }

  .navbar-sp {
    display: flex;
    background-color: $white;
    border-top: 1px solid #eee;
    padding: 0;
    height: 70px;

    .custom-nav {
      width: 100%;
      height: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: left;
      padding: 0;
      border: 0;
      border-left: 1px solid #eee;
      font-size: 1rem;

      &:last-child {
        border-right: 1px solid #eee;
      }

      .nav-div {
        margin-left: 5px;
        font-size: .875rem;
        line-height: 1.5em;
        color: #333;
      }
    }
  }

  .nav-menu-area-sp {
    display: none;
    position: relative;
    // height: 370px;

    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    ul {
      width: 100%;
    }

    .chat-service {
      a {
        text-decoration: none;
        color: black;
      }
    }

    .sp-menu-buttons {
      .header-button {
        button {
          width: 220px;
          margin: 12px auto;
          display: block;
          font-size: 12px;
        }
      }
    }


    .nav-list {
      border-top: 1px solid black;
      flex-direction: column;
      align-content: center;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;


      .nav-item {
        height: 40px;
        line-height: 40px;
        margin: 12px auto;

        div {
          cursor: pointer;
        }

        .disabled{
          color: #a9a9a9;
          pointer-events: none;
        }

      }
    }
  }

  @media screen and (max-width: 1380px) {
    #nav-controls {
      padding: 5px 10px;
    }
  }

  @media screen and (max-width: 1370px) {
    height: 150px;

    .header-menu {
      column-gap: 10px;
    }
  }

  @media screen and (max-width: 1279px) {
    height: 50px;

    #main {
      margin-top: 120px;
    }

    .navbar-brand {
      padding-left: 10px;
      width: 50%;

      #vciso-logo {
        width: 100%;
        height: auto;
      }
    }

    #nav-controls {
      display: none;
    }

    .user-info-sp {
      display: none;
    }

    .navbar-sp {
      display: none;
    }

    .icon-hamburger {
      display: block;
    }

    .header-menu {
      display: none;
    }

    .login-button,
    .logout-button {
      display: block;
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 60px;
      z-index: 100;
    }

    .registration-button {
      display: block;
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 120px;
      z-index: 100;
    }

    .menu-open {
      .top {
        transform: rotate(-45deg) translateY(0px);
        top: 14px;
      }

      .middle {
        background: rgba(51, 51, 51, 0);
        top: 10px;
      }

      .bottom {
        transform: rotate(45deg) translateY(0px);
        bottom: 9px;
      }
    }

    .show-menubar {
      display: block;
      height: 100vh;
      background-color: $white;
      padding: 0 15px;
    }

    .section-hr {
      color: #EEEEEE;
      margin: 30px auto;
    }

    .trial-button {
      padding-top: 20px;
    }
  }
}