//
// Yakkan styles
//

.Mypage {
    .btn {
        cursor: pointer;
        background: transparent;
        border: 1px solid #91c9ff;
        outline: none;
        transition: 0.3s ease-in-out;
    }

    .btn:hover {
        transition: 0.3s ease-in-out;
        background: #b6d9fa;
    }

    ul,
    li {
        list-style: none;
        padding: 0;
    }

    .mypage-buttons-area {
        width: 1000px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .mypage-button-container {
        border: 1px solid #ccc;
        margin: 20px;
        position: relative;
        background-color: #f2f7fa;

        .mypage-button-area {
            width: 200px;
            height: 130px;
            margin-top: 20px;

            .text-area {
                margin-top: 12px;
            }

            .link-area {
                position: absolute;
                width: 100%;
                left: 0;
                top: 0;
                height: 100%;
            }
        }
    }

    @media (max-width: 768px) {
        .mypage-buttons-area {
            width: 100%;
        }
    }

    @media (max-width: 460px) {
        .mypage-button-container {
            .mypage-button-area {
                font-size: 12px;
                width: 108px;
                height: 100px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding: 0 30px;
    }

    //     #main {
    //         margin: 120px 0 80px;
    //     }

    //     section,
    //     .content_title {
    //         max-width: 1004px;
    //         margin: 0 auto;
    //     }

    //     @media (max-width: 1279px) {
    //         #main {
    //             margin: 50px 0 60px;
    //         }
    //     }

    //     @media (max-width: 991px) {
    //         .box {
    //             padding: 0 15px;
    //         }
    //     }

    //     /*下層見出し*/

    //     .under_title {
    //         background: #f2f7fa;
    //         padding: 25px 0;

    //         h1 {
    //             font-size: 34px;
    //             margin-bottom: 0;
    //         }
    //     }

    //     @media (max-width: 991px) {
    //         .under_title {
    //             padding: 25px 0;

    //             h1 {
    //                 font-size: 18px;
    //             }
    //         }
    //     }

    //     .content_title {
    //         margin: 0 auto;
    //         padding: 70px 0 30px;

    //         &.fst_text {
    //             padding-top: 0;
    //         }

    //         div {
    //             font-size: 24px;
    //             line-height: 1.3em;
    //             padding-left: 14px;
    //             border-left: solid 6px #4d7aba;
    //         }
    //     }
    //     .template-customize-area .btn {
    //         display: block;
    //         width: 350px;
    //         font-size: 16px;
    //         color: #fff;
    //         background: #4d7aba;
    //         border-radius: 60px;
    //         position: relative;
    //         border: none;
    //         opacity: 1;
    //         margin: 0 auto;
    //         padding: 20px 0;

    //         &:before {
    //             content: "";
    //             width: 12px;
    //             height: 12px;
    //             // background: url("../../../assets/ac_arrow_on.svg") no-repeat center;
    //             background-size: 100% auto;
    //             position: absolute;
    //             right: 20px;
    //             top: 50%;
    //             transform: translateY(-50%) rotate(90deg);
    //         }
    //     }
    //     .required-lable {
    //         width: 40px;
    //         margin-right: 12px;
    //         margin-bottom: 0;
    //         //font-weight: 700;
    //     }
    //     .required-lable::after {
    //       font-size: 12px;
    //       display: inline-block;
    //       line-height: 28px;
    //       content: "必須";
    //       color: #fff;
    //       border-radius: 0;
    //       background: #dd821a;
    //       width: 34px;
    //       text-align: center;
    //     }
    //     .any-lable {
    //         width: 40px;
    //         margin-right: 12px;
    //         margin-bottom: 0;
    //         font-weight: 700;
    //     }
    //     .any-lable::after {
    //         font-size: 12px;
    //         display: inline-block;
    //         // margin: 0 0 0 20px;
    //         // padding: 3px 10px;
    //         content: "任意";
    //         color: #fff;
    //         border-radius: 0;
    //         background: #7a9fee;

    //         width: 34px;
    //         text-align: center;
    //     }
    //     .email_method_list {
    //       padding: 0;

    //       li {
    //           list-style: none;
    //           margin-top: 60px;

    //           &:first-of-type {
    //               margin-top: 0;
    //           }

    //           p {
    //               margin-bottom: 40px;
    //           }

    //           .row {
    //               align-items: center;
    //           }

    //           .btn-primary {
    //               display: block;
    //               width: 270px;
    //               font-size: 16px;
    //               color: #fff;
    //               border-color: #4d7aba;
    //               background: #4d7aba;
    //               border-radius: 50px;
    //               position: relative;
    //               margin: 0 auto;
    //               padding: 14px 0;

    //               &:before {
    //                   content: "";
    //                   width: 12px;
    //                   height: 12px;
    //                   // background: url("../assets/ac_arrow_on.svg") no-repeat
    //                   //     center;
    //                   background-size: 100% auto;
    //                   position: absolute;
    //                   right: 15px;
    //                   top: 50%;
    //                   transform: translateY(-50%) rotate(90deg);
    //               }
    //           }
    //       }
    //   }

    //   .e_file_area {
    //       margin-bottom: 40px;

    //       label {
    //           width: 100%;
    //           display: flex;
    //           justify-content: space-between;

    //           > div {
    //               &:nth-of-type(1) {
    //                   width: 152px;
    //               }

    //               &:nth-of-type(2) {
    //                   width: calc(100% - 240px);
    //               }
    //           }
    //       }

    //       input[type="file"] {
    //           display: none;
    //       }

    //       .file_btn {
    //           display: block;
    //           width: 200px;
    //           font-size: 16px;
    //           text-align: center;
    //           color: #fff;
    //           border-color: #4d7aba;
    //           background: #4d7aba;
    //           border-radius: 50px;
    //           position: relative;
    //           margin: 0 auto;
    //           padding: 14px 0;
    //       }
    //   }
}
