//
// About styles
//

.About {
  width: 1008px;
  margin: 0 auto;
  padding: 0 30px;
  
  @media screen and (max-width: 768px) {
    width: 100%;

    img {
      width: 100%;
    }
  }
}