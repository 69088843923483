//
// RyokinPlan styles
//

.RyokinPlan {
    /*********************************************************						
共通
**********************************************************/

    #header {
        z-index: 1000;
    }

    #main {
        margin: 120px 0 80px;
    }

    section,
    .content_title {
        max-width: 1004px;
        margin: 0 auto;
    }

    @media (max-width: 1279px) {
        #main {
            margin: 50px 0 60px;
        }
    }

    @media (max-width: 991px) {
        .box {
            padding: 0 15px;
        }
    }

    /*下層見出し*/

    .under_title {
        background: #f2f7fa;
        padding: 50px 0;

        h1 {
            font-size: 34px;
            margin-bottom: 0;
        }
    }

    @media (max-width: 991px) {
        .under_title {
            padding: 40px 0;

            h1 {
                font-size: 24px;
            }
        }
    }

    /*パンくず*/

    .vc_breadcrumb {
        padding-top: 12px;
        margin-bottom: 70px;

        ol {
            display: flex;
            list-style: none;
            padding: 0;

            li {
                font-size: 12px;
                color: #666;
                position: relative;
                padding-right: 32px;

                &:before,
                &:after {
                    content: "";
                    display: block;
                    width: 7px;
                    height: 1px;
                    background: #ccc;
                    position: absolute;
                    right: 12px;
                }

                &:before {
                    transform: rotate(45deg);
                    top: calc(50% - 2px);
                }

                &:after {
                    transform: rotate(-45deg);
                    top: calc(50% + 2px);
                }

                &.active {
                    padding: 0;

                    &:before,
                    &:after {
                        display: none;
                    }
                }

                a {
                    color: #4d7aba;
                    text-decoration: none;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .vc_breadcrumb {
            margin-bottom: 40px;
        }
    }

    @media (max-width: 430px) {
        .vc_breadcrumb ol li {
            font-size: 10px;
        }
    }

    /*本文*/

    .content_title {
        margin: 0 auto;
        padding: 120px 0 30px;

        &.fst_text {
            padding-top: 0;
        }

        div {
            font-size: 24px;
            line-height: 1.3em;
            padding-left: 14px;
            border-left: solid 6px #4d7aba;
        }
    }

    @media (max-width: 991px) {
        .content_title {
            padding: 80px 0 20px;

            div {
                font-size: 20px;
            }
        }
    }

    /*table*/

    .table {
        border-top: solid 1px rgb(179, 179, 179);
        border-bottom: solid 1px rgb(179, 179, 179);
        margin-bottom: 80px;

        tr {
            background: none;
        }

        th,
        td {
            font-size: 16px;
            vertical-align: middle;
            text-align: left !important;
            box-shadow: none;
        }

        th {
            width: 33.33%;
            border-right: none;
            background: #f2f7fa;
            padding: 30px 20px;
        }

        td {
            width: 66.66%;
            border-left: none;
            background: #fff;
            padding: 30px 40px;
        }
    }

    @media (max-width: 991px) {
        .table {
            display: block;
            width: 100%;
            border: none;

            tbody,
            tr,
            th,
            td {
                display: block;
                width: 100%;
                border: none;
            }

            border-bottom: solid 1px rgb(179, 179, 179);
            margin-bottom: 70px;

            th,
            td {
                font-size: 14px;
                padding: 14px 20px;
            }

            th {
                border-top: solid 1px rgb(179, 179, 179);
            }
        }
    }

    /*btn*/

    .template-customize-area .btn {
        display: block;
        width: 350px;
        font-size: 14px;
        color: #fff;
        background: #4d7aba;
        border-radius: 60px;
        position: relative;
        border: none;
        opacity: 1;
        margin: 0 auto;
        padding: 20px 0;

        &:before {
            content: "";
            width: 12px;
            height: 12px;
            //background: url("../assets/ac_arrow_on.svg") no-repeat center;
            background-size: 100% auto;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
        }
    }

    @media (max-width: 390px) {
        .template-customize-area .btn {
            width: 100%;
        }
    }

    @media (max-width: 360px) {
        .template-customize-area .btn:before {
            display: none;
        }
    }

    /*モーダル*/

    .modal-backdrop,
    .modal {
        z-index: 100002;
    }

    .modal-dialog {
        .modal-body {
            text-align: center;
        }

        .btn-primary {
            display: block;
            width: 270px;
            font-size: 14px;
            color: #fff;
            border-color: #4d7aba;
            background: #4d7aba;
            border-radius: 50px;
            position: relative;
            margin: 1rem auto;
            padding: 10px 0 !important;

            &:before {
                content: "";
                width: 12px;
                height: 12px;
                //background: url("../assets/ac_arrow_on.svg") no-repeat center;
                background-size: 100% auto;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }

    /*form*/

    .form-control,
    .form-select {
        border-radius: 3px;
        padding: 0.8rem 0.75rem;
    }

    /*********************************************************						
料金プラン
**********************************************************/

    .chatplusview {
        z-index: 10;
    }

    .price,
    .note {
        padding: 0;
        font-size: 16px;
    }

    .price li,
    .note li {
        list-style: none;
        margin: 0;
    }

    .price li {
        border-right: solid 1px #ddd;
    }

    .ring,
    .line {
        display: inline-block;
    }

    .ring {
        width: 23px;
        height: 23px;
        border-radius: 100%;
        border: solid 3px #4d7aba;
    }

    .line {
        width: 24px;
        height: 4px;
        background: #ccc;
    }

    .price_header .plan_category {
        border-top: solid 1px #ddd;
        border-left: solid 1px #ddd;
    }

    .price_table {
        border-top: solid 1px #ddd;
        align-items: center;

        &:last-of-type {
            border-bottom: solid 1px #ddd;
        }
    }

    .plan_category {
        font-size: 16px;
        background: #f2f7fa;
        padding: 26px 20px;
        border-right: solid 1px #ddd;
    }

    .price_header .plan_category {
        border-right: none;
    }

    .price_table {
        .plan_lite,
        .plan_standard,
        .plan_standard_mypage,
        .plan_premium {
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #4d7aba;
        }
    }

    .price {
        padding-top: 230px;
    }
    .price_mypage {
        padding-top: 20px;
    }

    .price_header {
        .plan_lite,
        .plan_standard,
        .plan_standard_mypage,
        .plan_premium {
            position: relative;
        }
    }

    li.price_header {
        border-right: none;
    }

    li.price_table {
        border-left: solid 1px #ddd;
    }

    .plan_box {
        position: absolute;
        width: 100%;
        bottom: -1px;

        .plan_name {
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            text-align: center;
            background: #83c25e;
            padding: 20px 0;
            margin: 0;

            br {
                display: none;
            }
        }

        .plan_body {
            border: solid 1px #ddd;
            padding: 24px 20px;

            p {
                font-size: 14px;
                margin-bottom: 20px;
            }

            .monthly,
            .yearly {
                font-size: 30px;
                color: #83c25e;
                font-weight: bold;
                text-align: center;
            }

            .monthly:before,
            .yearly:before {
                content: "￥";
                font-size: 18px;
                color: #83c25e;
                font-weight: normal;
            }

            .monthly:after {
                content: "/月";
                font-size: 16px;
                color: #444;
                font-weight: normal;
            }

            .yearly:after {
                content: "/年";
                font-size: 16px;
                color: #444;
                font-weight: normal;
            }
        }
    }
    .plan_box_mypage {
        // position: absolute;
        width: 100%;
        // bottom: -1px;

        .plan_name_mypage {
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            text-align: center;
            background: #83c25e;
            padding: 20px 0;
            margin: 0;

            br {
                display: none;
            }
        }

        .plan_body_mypage {
            border: solid 1px #ddd;
            padding: 24px 20px;

            p {
                font-size: 14px;
                margin-bottom: 20px;
            }

            .monthly,
            .yearly {
                font-size: 30px;
                color: #83c25e;
                font-weight: bold;
                text-align: center;
            }

            .monthly:before,
            .yearly:before {
                content: "￥";
                font-size: 18px;
                color: #83c25e;
                font-weight: normal;
            }

            .monthly:after,
            .yearly:after {
                content: "/月";
                font-size: 16px;
                color: #444;
                font-weight: normal;
            }
        }
    }

    .price_header {
        .plan_standard_mypage .plan_box_mypage {
            .plan_name_mypage {
                background: #6aafde;
            }

            .plan_body_mypage {
                border-color: #6aafde;
            }

            .monthly,
            .yearly {
                color: #6aafde;
            }

            .plan_body {
                .monthly:before,
                .yearly:before {
                    color: #6aafde;
                }
            }
        }
        .plan_standard_mypage .plan_box_mypage {
            z-index: 2;

            .plan_name_mypage {
                background: #6aafde;
                position: relative;
            }

            .plan_body_mypage {
                border-color: #6aafde;
            }

            .monthly,
            .yearly {
                color: #6aafde;
            }

            .plan_body_mypage {
                .monthly:before,
                .yearly:before {
                    color: #6aafde;
                }
            }
        }
        .plan_premium_mypage .plan_box_mypage {
            .plan_name_mypage {
                background: #4f7dbf;
            }

            .monthly,
            .yearly {
                color: #4f7dbf;
            }

            .plan_body_mypage {
                .monthly:before,
                .yearly:before {
                    color: #4f7dbf;
                }
            }
        }
    }
    .price_header {
        .plan_standard .plan_box {
            z-index: 2;

            .plan_name {
                background: #6aafde;
                position: relative;
                padding: 32px 0 20px;

                &:before {
                    content: "オススメ";
                    display: block;
                    width: 90px;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 1;
                    color: #fff;
                    text-align: center;
                    background: #dfcc25;
                    padding: 10px 0;
                    position: absolute;
                    left: 7px;
                    top: -7px;
                    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
                }
            }

            .plan_body {
                border-color: #6aafde;
                border-width: 2px;
            }

            .monthly,
            .yearly {
                color: #6aafde;
            }

            .plan_body {
                .monthly:before,
                .yearly:before {
                    color: #6aafde;
                }
            }
        }
        .plan_standard_mypage .plan_box {
            z-index: 2;

            .plan_name {
                background: #6aafde;
                position: relative;
                // padding: 32px 0 20px;

                // &:before {
                //     content: "オススメ";
                //     display: block;
                //     width: 90px;
                //     font-size: 14px;
                //     font-weight: bold;
                //     line-height: 1;
                //     color: #fff;
                //     text-align: center;
                //     background: #dfcc25;
                //     padding: 10px 0;
                //     position: absolute;
                //     left: 7px;
                //     top: -7px;
                //     box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
                // }
            }

            .plan_body {
                border-color: #6aafde;
                // border-width: 2px;
            }

            .monthly,
            .yearly {
                color: #6aafde;
            }

            .plan_body {
                .monthly:before,
                .yearly:before {
                    color: #6aafde;
                }
            }
        }
        .plan_premium .plan_box {
            .plan_name {
                background: #4f7dbf;
            }

            .monthly,
            .yearly {
                color: #4f7dbf;
            }

            .plan_body {
                .monthly:before,
                .yearly:before {
                    color: #4f7dbf;
                }
            }
        }
    }
    @media (max-width: 991px) {
        .price {
            padding-top: 250px;
        }

        .ring {
            width: 20px;
            height: 20px;
        }

        .line {
            width: 20px;
            position: relative;
            top: -6px;
        }

        .price_header .plan_category {
            display: none;
        }

        .price_table {
            .plan_category {
                font-size: 14px;
                text-align: center;
                border-right: none;
                border-bottom: solid 1px #ddd;
                padding: 10px 0;
            }

            .plan_lite,
            .plan_standard,
            .plan_standard_mypage,
            .plan_premium {
                font-size: 13px;
                padding: 20px 0;
            }
        }

        .plan_box .plan_name {
            br {
                display: inline-block;
            }

            vertical-align: middle;
            padding: 0;
            width: 100%;
            height: 73px;

            > div {
                display: table-cell;
                vertical-align: middle;
                width: 500px;
                height: 73px;
            }
        }

        .price_header .plan_standard .plan_box .plan_name {
            height: 81px;
            padding: 0;

            > div {
                height: 81px;
                position: relative;
                top: 8px;
            }
        }

        .plan_box .plan_body {
            padding: 12px 8px;

            .monthly,
            .yearly {
                font-size: 18px;
            }

            .monthly:before,
            .yearly:before {
                font-size: 12px;
            }

            .monthly:after,
            .yearly:after {
                font-size: 14px;
            }
        }

        .price_header .plan_standard .plan_box .plan_name:before {
            left: 4px;
            top: -4px;
            font-size: 12px;
            width: 62px;
            padding: 7px;
        }
    }

    @media (max-width: 500px) {
        .price {
            padding-top: 300px;
        }
    }

    @media (max-width: 400px) {
        .plan_box {
            .plan_name {
                font-size: 16px;
            }

            .plan_body {
                .monthly,
                .yearly {
                    font-size: 13px;
                }

                .monthly:after,
                .yearly:after {
                    font-size: 10px;
                }
            }
        }
    }
}
