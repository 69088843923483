.App {
  text-align: center;
}

body {
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 480px) {
  #signup-mail {
    font-size: .7rem;
  }
}

@media (max-width: 786px){
  .text-align-sm {
    text-align: center;
  }

  .hide-button-sm {
    display: none
  }

  .fill-height-sm {
    height: calc(100vh - (66px + 15vh ))
  }

  .fill-height-incident-sm {
    height: calc(100vh - (66px + 30vh ))
  }

  .padding-sm {
    padding-right: .5rem !important;
    padding-bottom: 0rem !important;
    padding-left: .1rem !important;
  }

  .chat-window-height-sm {
    min-height: 500px;
  }
}

@media (min-width: 786px) and (min-height: 766px) {
  .text-align-md {
    text-align: end
  }
  
  .show-button-md {
    display: flex
  }

  .fill-height-incident-md {
    height: calc(100vh - (66px + 50px + 30vh ))
  }

  .fill-height-md {
    height: calc(100vh - (66px + 50px + 15vh))
  }
}

@media (min-width: 1200px)  and (min-height: 767px) {
  .fill-height-xl {
    height: calc(100vh - (66px + 50px + 15vh ))
  }
  .fill-height-incident-xl {
    height: calc(100vh - (66px + 50px + 30vh))
  }
}

#main {
  margin-top: 170px;
}

#admin-template-upload > label {
  width: 80%;
  cursor: pointer
}

.error-message-left > p {
  text-align: left !important;
}

#secreport-upload > label {
  width: 80%;
  cursor: pointer
}

#secreport-date-error > p {
  text-align: left !important;
}

@media screen and (max-width: 1279px) {
  #main {
    margin-top: 102px;
  }
}

#root {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}

#chatplusview, chat {
  position: relative !important;
  height: 100% !important;
}

#chatplusview.chatplusview-modern {
  width: 100% !important
}

.breadcrumb-item+.breadcrumb-item::before {
  content:var(--bs-breadcrumb-divider,">") !important;
}

#tokusyouTable > tbody > tr > td:first-child {
  background-color: #e9ebf5;
  vertical-align: middle;
  height: 45px;
}

#tokusyouTable > tbody > tr > td:nth-child(2) {
  text-align: left;
  padding-left: 30px;
  vertical-align: middle;
}

#tokusyouTable {
  border-color: white;
  font-size: .9rem;
}

#counseling-table {
  border-color: transparent;
  font-size: .95rem;
  vertical-align: middle;
}

#counseling-table > tbody > tr > td {
  text-align: left;
}

#counseling-table > tbody > tr > td:nth-child(2) {
  padding-left: 30px;
}

.nav-link:hover {
  color: #385093 !important;
  border-bottom: 5px solid #385093; 
  fill: #385093;
}

.custom-nav {
  justify-content: center
}

.nav-link:hover > svg *, .nav-link:hover > div {
  color: #385093 !important;
  fill: #385093 !important;
}

.nav-link.active, .nav-link.active > svg * {
  color: #385093 !important;
  fill: #385093 !important;
  border-bottom: 5px solid #385093;
}

.nav-link.active > #nav-lock g #icon * {
  stroke: #385093 !important;
  fill: none;
}

.nav-link.active > #nav-lock g #border path:nth-child(2) {
  fill: #385093 !important;
}

.nav-link.active > #nav-lock g #border path:nth-child(1) {
  fill: none!important;
}

.nav-link.active > #nav-lock g #corner {
  fill: #385093 !important;
  stroke: #385093 !important;
}

.nav-link.active > #nav-page g g g path:nth-child(2) {
  fill: #385093 !important;
}

.nav-link.active > #nav-page g line {
  stroke: #385093 !important;
  fill: none !important;
}

.nav-link.active > #nav-page g g #page-corner {
  stroke: #385093 !important;
  fill: #385093 !important;
}

.nav-link.active > #nav-page g g #circle_back {
  fill: #385093 !important;
}

.nav-link.active > #nav-page g > path {
  fill: none !important;

}

/* hover styles for weird svgs*/
.nav-link:hover > #nav-lock g #icon * {
  stroke: #385093 !important;
  fill: none;
}

.nav-link:hover  > #nav-lock g #border path:nth-child(2) {
  fill: #385093 !important;
}

.nav-link:hover  > #nav-lock g #border path:nth-child(1) {
  fill: none!important;
}

.nav-link:hover  > #nav-lock g #corner {
  fill: #385093 !important;
  stroke: #385093 !important;
}

.nav-link:hover  > #nav-page g g g path:nth-child(2) {
  fill: #385093 !important;
}

.nav-link:hover  > #nav-page g line {
  stroke: #385093 !important;
  fill: none !important;
}

.nav-link:hover  > #nav-page g g #page-corner {
  stroke: #385093 !important;
  fill: #385093 !important;
}

.nav-link:hover  > #nav-page g g #circle_back {
  fill: #385093 !important;
}

.nav-link:hover  > #nav-page g > path {
  fill: none !important;

}

/* Web service icon styles for lock and page */

 #service-lock g #icon * {
  stroke: #385093 !important;
  fill: none;
}

 #service-lock g #border path:nth-child(2) {
  fill: #385093 !important;
}

#service-lock g #border path:nth-child(1) {
  fill: none!important;
}

#service-lock g #corner {
  fill: #385093 !important;
  stroke: #385093 !important;
}

#service-page g g g path:nth-child(2) {
  fill: #385093 !important;
}

#service-page g line {
  stroke: #385093 !important;
  fill: none !important;
}

#service-page g g #page-corner {
  stroke: #385093 !important;
  fill: #385093 !important;
}

#service-page g g #circle_back {
  fill: #385093 !important;
}

#service-page g > path {
  fill: none !important;

}

#service-meeting > path {
  stroke: #385093 !important;
}



.nav-link {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: lightgray darkgray;
}

/* Works on Chrome, Edge, and Safari */
#incidenthistory::-webkit-scrollbar {
  width: 4px;
}

#incidenthistory::-webkit-scrollbar-track {
  background: lightgray;
}

#incidenthistory::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 4px;
  border: 1px solid darkgray;
}

#invoice-history::-webkit-scrollbar {
  width: 4px;
}

#invoice-history::-webkit-scrollbar-track {
  background: lightgray;
}

#invoice-history::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 4px;
  border: 1px solid darkgray;
}

.thumbnail {
  display: block;
  overflow: hidden;
  height: 150px;
  width: 100%;
}

.thumbnail img {
 display: block;
 width: 100%;
}

#bg-test {
  background-color: #F3F7FB;
  background-image: url(./assets/bg.png);
  background-repeat: no-repeat;
  background-position: right top;
  /*background-size: cover; */
  color: #444;
  overflow: hidden;
  min-height: 350px;
  
}

#bg-test.hide-background {
  background-image: none;
}

#bg-pc {
  background-size: auto 85%;
  height: calc(50vh - 100px);
}

#fab {
  cursor: pointer;
  position: fixed;
  z-index: 100002;
  bottom: 20px;
  right: 0;
  width: 270px;
  height: 130px;
  background-color: #EB5A78;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  padding: 40px 10px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .16);
}
#fab .fab-title {
  position: absolute;
  background-color: #fff;
  border: solid 2px #EB5A78;
  border-radius: 5px;
  color: #EB5A78;
  width: 230px;
  padding: 5px;
  top: -15px;
  left: calc(50% - 115px);
}
#fab .sp {
  display: none;
}

#footer-links a.disabled {
  pointer-events: none;
  color: darkgray !important;
}

.gsx-article h1 {
  font-size: 2.8rem;
  font-weight: 700;
}
.gsx-article  h2 {
  font-size: 1.4rem;
  font-weight: 700;
  color: #385093;
  border-bottom: 1px solid #ccc;
  border-left: 5px solid #385093;
  padding-left: 0.5rem;
  padding-bottom: 5px;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.gsx-aside-ranking div > a {
  text-decoration: none;
  color: #4a4a4a;
}
.gsx-aside-ranking > h2 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #385093;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.gsx-aside-ranking h3 {
  font-size: 1rem;
  font-weight: 700;
  color: #385093;
}

.modal {
  z-index: 100003 !important;
}

#file-upload-col > label {
  width: 100%
}

#header {
  top: 0;
  position:fixed;
  width: 100%;
  z-index: 100002;
  transition: top .85s
}

#header.scroll {
  top: 0;
  position:fixed;
  transition: .1s ease-in-out;
}

#topnav {
  transition: .1s ease-in-out;
}

#header.scroll #topnav {
  height: 50px !important; 
}

#header.scroll #topnav .navbar {
  font-size: 1rem;
  height: 37px;
}

/* #header.scroll #topnav .navbar span #vciso-logo {
  height: 27px;
} */

/* #header.scroll #breadcrumb-row .breadcrumb-item {
  font-size: .8rem;
} */

/* #header.scroll #breadcrumb-row {
  height: 35px !important;
  line-height: 35px !important;
} */

.nav-div, #topnav, .custom-nav, .gsx-beforeapp-nav, #before-nav,#nav-controls, #breadcrumb-row {
  transition: .3s ease-in-out;
}

.custom-nav {
  transition: .1s ease-in-out;
}


.gsx-beforeapp-nav.scroll {
  height: 400px;
  transition: all ease .85s;
}
.gsx-beforeapp-nav.scroll div > .custom-nav > .nav-div {
  align-self: center;
  font-size: .8rem;
  margin-left: 10px
}


.gsx-beforeapp-nav.scroll > div > .custom-nav {
  flex-direction: row;
}

#header.HeightMin{
  position: fixed;
    z-index: 999;/*最前面へ*/
  height:70px;
  animation: DownAnime 0.5s forwards;
}

@keyframes DownAnime{
  from {
    opacity: 0;
  transform: translateY(-170px);
  }
  to {
    opacity: 1;
  transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  #bg-test {
    background-image: url(./assets/bg_sp.png);
  }
  #bg-test.logged-in {
    background-image: url(./assets/bg_sp_logged-in.png);
    background-position: left top;
  }
  .breadcrumb-item {
    font-size: 10px;
  }
}