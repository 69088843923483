//
// Home styles
//

.Home {
  margin-top: -80px;

  .section-title-block {
    text-align: center;
    margin-bottom: 50px;
    .section-title {
      color: #444;
      font-size: 40px;
      font-weight: 500;
      text-align: center;
      padding-bottom: 20px;
      &::after {
        content: "";
        display: block;
        width: 40px;
        height: 1px;
        border-bottom: 2px solid #4D7ABA;
        margin: 20px auto 0 auto;
      }
    }
    .subtext {
      color: #444;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.87em;
    }
  }
  .section-subtitle-block {
    text-align: center;
    .section-title {
      position: relative;
      display: inline-block;
      padding: 0 40px;
      color: #444;
      font-size: 32px;
      font-weight: 500;
      margin-bottom: 30px;
      &:before, &:after {
        content: '';
        position: absolute;
        top: 50%;
        display: inline-block;
        width: 20px;
        height: 3px;
        background-color: #316DC4;
      }
      &:before {
        left:0;
      }
      &:after {
        right: 0;
      }
    }
  }
  .section-hr {
    color: #CCCCCC;
    margin: 60px 0;
  }

  .hide-fb-text {
    display: block;
  }

  #security-articles {
    padding: 70px 0;
    background-color: #F5F5F5;


  }
  #interviews {
    padding: 70px 0;
  }
  #web-service {
    padding: 70px 0;
    background-color: #F2F7FA;
    .service-list {
      justify-content: center;
      .list-item {
        .wrapper {
          height: 100%;
          display: flex;
          flex-direction: column;
          min-height: 375px;
          box-sizing: border-box;
          background-color: $white;
          padding: 30px;
          .plan-point-list {
            margin-top: auto;
          }
        }
      }
    }
    .plan-point {
      &::after {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 16px;
      }
      &.lite {
        &::after {
          background-color: $lite-plan-color;
        }
      }
      &.standard {
        &::after {
          background-color: $standard-plan-color;
        }
      }
      &.premium {
        &::after {
          background-color: $premium-plan-color;
        }
      }
    }
  }
  #plans {
    .plans-list {
      margin: 4rem 0;
      .list-item {
        display: flex;
        flex-direction: column;
        .item-title {
          padding: 30px;
          text-align: center;
          color: $white;
          font-size: 24px;
          font-weight: 700;
        }
        .item-body {
          padding: 30px;
          background-color: $white;
          height: 100%;
          display: flex;
          flex-direction: column;
        }
        .price {
          text-align: center;
          margin-bottom: 45px;
          max-height: 16px;
        }
        &.plan-lite {
          padding-bottom: 1rem;
          .item-title {
            background-color: $lite-plan-color;
          }
          .price {
            color: $lite-plan-color;
          }
        }
        &.plan-standard {
          margin-top: -1rem;
          border: solid 2px $standard-plan-color;
          .item-title {
            background-color: $standard-plan-color;
          }
          .price {
            color: $standard-plan-color;
          }
        }
        &.plan-premium {
          padding-bottom: 1rem;
          .item-title {
            background-color: $premium-plan-color;
          }
          .price {
            color: $premium-plan-color;
          }
        }
        &.plan-recommend {
          position: relative;
          &::before {
            content: "オススメ";
            display: block;
            background-color: #DFCC25;
            color: $white;
            width: 94px;
            height: 33px;
            text-align: center;
            font-size: 14px;
            font-weight:bold;
            line-height: 2em;
            position: absolute;
            top: -10px;
            left: 10px;
          }
        }
      }
    }
    .btn-arrow-yellow {
      margin: 0 auto;
      padding: 40px;
      border-radius: var(--bs-border-radius-pill);
      flex-wrap: wrap;
      justify-content: center;
      &::after {
        border-top: solid 4px #fff;
        border-right: solid 4px #fff;
        width: 20px;
        height: 20px;
        right: 45px;
      }
      .vciso-logo {
        width: 130px;
        height: auto;
        margin-right: 5px;
      }
      span {
        font-weight: 700;
        font-size: 24px;
        &.subtext {
          font-size: 18px;
        }
      }
    }
  }
  @media (min-width: 769px) {
    .sp {
      display: none;
    }
    #web-service {
      .service-plan-types {
        justify-content: center;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .pc {
      display: none;
    }
    .sp {
      display: block;
    }

    #fab {
      width: 100%;
      height: 65px;
      bottom: 0;
      padding: 0;
      .fab-title {
        display: none;
      }
      .fab-text {
        font-size: 16px;
        margin-top: 20px;
        margin-left: 16px;
      }
      .fab-body {
        height: 100%;
      }
      .pc {
        display: none;
      }
      .sp {
        display: block;
      }
    }

    .section-title-block {
      .section-title {
        font-size: 30px;
        padding-bottom: 20px;
      }
      .subtext {
        font-size: 14px;
        line-height: 1.5em;
      }
    }
    .section-subtitle-block {
      text-align: center;
      .section-title {
        font-size: 22px;
        margin-bottom: 30px;
      }
    }

    #security-articles {

    }
    #web-service {
      .section-title-block {
        .section-title {
          font-size: 26px;
        }
        .title-logo {
          width: 120px;
          height: auto;
        }
      }
      .service-list {
        justify-content: flex-start;
        .list-item {
          .wrapper {
            padding: 20px 10px;
          }
        }
      }
      .service-plan-types {
        font-size: 14px;
        justify-content: flex-start;
        align-items: flex-start;
        .service-plan-type-item {
          align-items: flex-start;
        }
      }
    }
    #plans {
      .plans-list {
        .list-item {
          margin-bottom: 1rem;
          &.plan-lite {
            order: 2;
            padding: 0;
            border: solid 2px $lite-plan-color;
          }

          &.plan-standard {
            order: 1;
            //margin-top: -1rem;
          }

          &.plan-premium {
            order: 3;
            padding: 0;
            border: solid 2px $premium-plan-color;
          }
        }
      }
      .btn-arrow-yellow {
        padding: 20px;
        margin: 0 auto;
        border-radius: 0;
        justify-content: flex-start;
        &::after {
          border-top: solid 3px #fff;
          border-right: solid 3px #fff;
          width: 10px;
          height: 10px;
          right: 20px;
        }
        .vciso-logo {
          width: 102px;
          height: auto;
        }
        span {
          font-size: 18px;
          &.subtext {
            font-size: 14px;
          }
        }
      }
    }
    margin-top: -50px;
  }

}