//
// Login styles
//

.Login {
    // max-width: "450px";
    // margin-bottom: "10vh";
    // border-radius: ".5rem";
    // display: "flex";
    // justify-content: "center";
    // align-items: "center";
    // align-self: "center";

    input {
        width: 400px;
        font-size: 16px;
        // border: 1px solid #ccc;
        border: solid 1px #d1cbbb;
        border-radius: 4px;
        background: #fdfce2;
        //box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }
    label {
        width: 360px;
        line-height: 36px;
        margin-bottom: 0;
        //font-weight: 700;
        font-size: 16px;
        // background-color: #edebe4;
    }
    .input-mail {
        width: 500px;
        font-size: 16px;
        // border: 1px solid #ccc;
        border: solid 1px #d1cbbb;
        border-radius: 4px;
        background: #fdfce2;
        //box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }
    .label-password {
        width: 130px;
        font-size: 16px;
        text-align: left;
    }
    .label-forgot-password {
        width: 265px;
        font-size: 16px;
        text-align: right;
    }
    .edit-roboter-form-area {
        margin-top: 30px;
        height: 100px;
        max-width: 800px;
    }
    .edit-lable-form-area {
        //margin-top: 30px;
        max-width: 500px;
    }
    .edit-form-area {
        //margin-top: 30px;
        height: 100%;
        max-width: 500px;
    }
    .edit-phone-form-area {
        //margin-top: 30px;
        height: 52px;
        max-width: 500px;
    }
    .button-margin {
        margin-right: 30px !important;
    }

    .label-manager {
        width: 224px;
        line-height: 36px;
        margin-bottom: 0;
        //font-weight: 700;
        font-size: 16px;
        // background-color: #edebe4;
    }

    .edit-form-area {
        // margin-bottom: 12px;
        height: 50px;
    }
    .form-area {
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        width: 500px;
    }
    .button-area {
        margin-top: 14px;
        display: flex;
        justify-content: center;
    }
    .button-size {
        width: 260px;
    }
    .button-size-2 {
        width: 160px;
    }
    .button-margin {
        // margin-left: 4px;
    }
    @media screen and (max-width: 768px) {
        padding: 0 30px;
        input {
            font-size: 14px;
        }
        .form-area {
            width: 350px;
        }
        .input-mail {
            width: 302px;
        }
    }
    @media screen and (max-width: 390px) {
        padding: 0 30px;
        input {
            width: 358px;
        }
        .label-forgot-password {
            width: 230px;
        }
        .error-area {
            width: 358px;
        }
    }
}
