//
// Inqury styles
//

.Inquiry {

  .incident-select {
    width: fit-content;
  }
  .incident-result-text-area {
    background-color: red;
    border-radius: 4px;
  }
  

  @media screen and (max-width: 768px) {
    .inquiry-width {
      width: 360px;
    }
  }
}