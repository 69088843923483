//
// ChangePassword styles
//

.ChangePassword {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-self: center;
    font-size: 16px;

    input {
        width: 350px;
        font-size: 16px;
        // border: 1px solid #ccc;
        border: solid 1px #d1cbbb;
        border-radius: 4px;
        background: #fdfce2;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }
    label {
        width: 180px;
        margin-right: 12px;
        line-height: 36px;
        margin-bottom: 0;
    }

    .button-register {
        width: 190px;
        align-self: center;
        background-color: #4383E1;
    }
    .passowrd-form-area {
        margin-bottom: 12px;
        height: 52px;
    }
    .form-area {
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        width: 542px;
    }
    .button-area {
        margin-top: 12px;
        display: flex;
        justify-content: right;
    }
    .message-area{
        text-align: right;
        margin-top: 4px;
        font-size: .8rem;
        font-weight: bold;
        color: red;
    }
    @media screen and (max-width: 768px) {
        padding: 0 30px;

        .form-area {
            width: 350px;
        }
        .passowrd-form-area {
            margin-bottom: 42px;
        }
    }
}
