//
// EmailTraining styles
//

.Common {
    /*********************************************************						
共通
**********************************************************/

    // border: solid gray 1pt;

    #header {
        z-index: 1000;
    }

    #main {
        margin: 120px 0 80px;
    }

    section,
    .content_title {
        max-width: 1004px;
        margin: 0 auto;
    }
    .mypage-section {
        max-width: 800px;
        margin: 0 auto;
        .text {
            background: #fdfce2;
        }
        .input-area {
            font-size: 16px;
            border: solid 1px #d1cbbb;
            border-radius: 4px;
            background: #fdfce2;
            box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        }
    }

    @media (max-width: 1279px) {
        #main {
            margin: 50px 0 60px;
        }
    }

    @media (max-width: 991px) {
        .box {
            padding: 0 15px;
        }
    }

    /*下層見出し*/

    .under_title {
        background: #f2f7fa;
        padding: 25px 0;

        h1 {
            font-size: 34px;
            margin-bottom: 0;
        }
    }

    @media (max-width: 991px) {
        .under_title {
            padding: 25px 0;

            h1 {
                font-size: 16px;
            }
        }
    }

    /*パンくず*/

    .vc_breadcrumb {
        padding-top: 12px;
        margin-bottom: 70px;

        ol {
            display: flex;
            list-style: none;
            padding: 0;

            li {
                font-size: 12px;
                color: #666;
                position: relative;
                padding-right: 32px;

                &:before,
                &:after {
                    content: "";
                    display: block;
                    width: 7px;
                    height: 1px;
                    background: #ccc;
                    position: absolute;
                    right: 12px;
                }

                &:before {
                    transform: rotate(45deg);
                    top: calc(50% - 2px);
                }

                &:after {
                    transform: rotate(-45deg);
                    top: calc(50% + 2px);
                }

                &.active {
                    padding: 0;

                    &:before,
                    &:after {
                        display: none;
                    }
                }

                a {
                    color: #4d7aba;
                    text-decoration: none;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .vc_breadcrumb {
            margin-bottom: 40px;
        }
    }

    @media (max-width: 430px) {
        .vc_breadcrumb ol li {
            font-size: 10px;
        }
    }

    /*本文*/

    .content_title {
        margin: 0 auto;
        padding: 25px 0 15px;

        &.fst_text {
            padding-top: 0;
        }

        div {
            font-size: 24px;
            line-height: 1.3em;
            padding-left: 14px;
            border-left: solid 6px #4d7aba;
        }
    }

    @media (max-width: 991px) {
        .content_title {
            padding: 40px 0 20px;

            div {
                font-size: 20px;
            }
        }
    }

    .table {
        border: solid 0.2px rgb(179, 179, 179);
        // border-bottom: solid 1px rgb(179, 179, 179);
        // border-top: solid 1px rgb(179, 179, 179);
        // margin-bottom: 80px;
        --bs-table-hover-bg: #abe1fa;

        tr {
            background: #f2f7fa;
        }

        th,
        td {
            font-size: 16px;
            vertical-align: middle;
            // text-align: left !important;
            // box-shadow: none;
            background: #f2f7fa;
        }

        th {
            width: 10%;
            // border-right: none;
            background: #f2f7fa;
            padding: 15px 20px;
        }
        .td-1 {
            width: 5%;
            // border-left: none;
            background: #fff;
            padding: 15px 25px;
            text-align: left;
        }
        .td-2 {
            width: 25%;
            // border-left: none;
            background: #fff;
            padding: 15px 25px;
            text-align: left;
        }
        .td-3 {
            width: 35%;
            // border-left: none;
            background: #fff;
            padding: 15px 25px;
            text-align: left;
        }
        .td-4 {
            width: 25%;
            // border-left: none;
            background: #fff;
            padding: 15px 25px;
            text-align: left;
        }
        .td-5 {
            width: 20%;
            // border-left: none;
            background: #fff;
            padding: 15px 25px;
            text-align: left;
        }

        td {
            width: 45%;
            // border-left: none;
            background: #fff;
            padding: 15px 40px;
        }
    }
    tr:hover {
        background-color: #f2f7fa;
    }
    @media (max-width: 991px) {
        .table {
            display: block;
            width: 100%;
            border: none;

            tbody,
            tr,
            th,
            td {
                display: block;
                width: 100%;
                border: none;
            }

            border-bottom: solid 1px rgb(179, 179, 179);
            margin-bottom: 70px;

            th,
            td {
                font-size: 14px;
                padding: 14px 20px;
            }

            th {
                border-top: solid 1px rgb(179, 179, 179);
            }
        }
    }

    /*btn*/

    .template-customize-area .btn {
        display: block;
        width: 350px;
        font-size: 16px;
        color: #fff;
        background: #4d7aba;
        border-radius: 60px;
        position: relative;
        border: none;
        opacity: 1;
        margin: 0 auto;
        padding: 20px 0;

        &:before {
            content: "";
            width: 12px;
            height: 12px;
            //background: url("../assets/ac_arrow_on.svg") no-repeat center;
            background-size: 100% auto;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
        }
    }
    .template-customize-mypage-area .btn {
        display: block;
        width: 180px;
        font-size: 16px;
        color: #fff;
        background: #4d7aba;
        border-radius: 60px;
        position: relative;
        border: none;
        opacity: 1;
        margin: 0 auto;
        padding: 16px 0;
        margin-top: 20px;

        &:before {
            content: "";
            width: 12px;
            height: 12px;
            //background: url("../assets/ac_arrow_on.svg") no-repeat center;
            background-size: 100% auto;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
        }
    }
    .mypage-margin {
        margin-bottom: 10px;
    }

    @media (max-width: 390px) {
        .template-customize-area .btn {
            // width: 100%;
        }
    }

    @media (max-width: 360px) {
        .template-customize-area .btn:before {
            display: none;
        }
    }

    /*モーダル*/

    .modal-backdrop,
    .modal {
        z-index: 100002;
    }

    .modal-dialog {
        .modal-body {
            text-align: center;
        }

        .btn-primary {
            display: block;
            width: 270px;
            font-size: 14px;
            color: #fff;
            border-color: #4d7aba;
            background: #4d7aba;
            border-radius: 50px;
            position: relative;
            margin: 1rem auto;
            padding: 10px 0 !important;

            &:before {
                content: "";
                width: 12px;
                height: 12px;
                //background: url("../assets/ac_arrow_on.svg") no-repeat center;
                background-size: 100% auto;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }

    /*form*/

    .form-control,
    .form-select {
        // align-self: center;
        border-radius: 3px;
        padding: 0.8rem 0.75rem;
        // margin-left: 4px;
        background: #fdfce2;
        appearance: auto;
        border: solid 1px #d1cbbb;
        border-radius: 4px;
        background: #fdfce2;
        // box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }

    /*********************************************************						
  合同メール訓練
  **********************************************************/

    .sub_title {
        font-size: 18px;
        color: #4d7aba;
        margin-bottom: 24px;
    }

    @media (max-width: 991px) {
        .sub_title {
            font-size: 16px;
        }

        .btn-margin {
            margin-bottom: 5px !important;
        }
    }

    .download-area {
        margin-bottom: 40px;

        > div {
            padding: 0 5px;
        }

        .dl_list {
            height: 100%;
            border: solid 1px #ccc;
            position: relative;

            h3,
            p {
                font-size: 16px;
            }

            h3 {
                text-align: center;
                background: #f2f7fa;
                padding: 15px;
                margin-bottom: 0;
            }

            div {
                padding: 25px 15px 80px;
            }
        }

        .btn-outline-primary {
            display: block;
            width: 188px;
            font-size: 14px;
            color: #4d7aba;
            border-color: #4d7aba;
            border-radius: 50px;
            margin: 0 auto;
            padding: 10px 0;
            position: absolute;
            left: 50%;
            bottom: 20px;
            transform: translateX(-50%);

            &:before {
                content: "";
                width: 12px;
                height: 12px;
                //background: url("../assets/ac_arrow.svg") no-repeat center;
                background-size: 100% auto;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
            }

            &:hover {
                color: #fff;
                background-color: #4d7aba;
            }
        }
    }

    .template-customize-area p {
        margin-bottom: 40px;
    }

    @media (max-width: 991px) {
        .doc_lede,
        .download-area .dl_list p {
            font-size: 14px;
            line-height: 1.5em;
        }

        .template-customize-area p {
            font-size: 14px;
        }
    }

    @media (max-width: 768px) {
        .download-area > div {
            padding: 0;
            margin-bottom: 20px;

            &:nth-of-type(1),
            &:nth-of-type(3) {
                padding: 0;
                margin-bottom: 20px;
            }
        }
    }

    .email_method {
        margin-top: 40px;

        ol {
            padding-left: 1.2em;
        }

        li {
            margin-top: 0.5em;
        }
    }

    .download-area.email-type {
        .dl_list {
            h3 {
                text-align: left;
                position: relative;
                padding-right: 70px;

                span {
                    display: inline-block;
                    background: #4d7aba;
                    font-size: 12px;
                    color: #fff;
                    line-height: 1em;
                    padding: 5px 8px 7px;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);

                    &.active {
                        background: #9a9a9a;
                    }
                }
            }

            > div {
                padding: 25px 15px 0;
            }

            .e_pro {
                padding: 0;

                h4 {
                    font-size: 14px;
                    margin-bottom: 5px;
                }

                p {
                    margin-bottom: 20px;
                }
            }
        }

        .btn-outline-primary {
            width: 100%;
            position: static;
            transform: translateX(0);
            margin-top: 12px;

            &.report {
                border-color: #ccc;
                background: #ccc;
                color: #fff;

                &:before {
                    display: none;
                }
            }
        }

        .email-card {
            margin-bottom: 75px;
        }

        .abled {
            cursor: pointer;
            .dl_list:hover {
                box-shadow: 2px 4px 16px rgb(0 0 0 / 16%);
                transform: scale3d(1.01, 1.01, 1.01);
                transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
                background-color: #abe1fa;
            }
        }
        .disabled {
            cursor: not-allowed;
            .dl_list:hover {
                background-color: #ccc;
            }
        }
    }

    // border: 1px solid #1a1a1a;

    @media (max-width: 991px) {
        .email_method li {
            font-size: 14px;
        }

        .download-area.email-type {
            > div {
                margin-bottom: 36px;
            }

            .dl_list {
                height: auto;
            }
        }
    }
    .required-lable {
        width: 40px;
        margin-right: 12px;
        margin-bottom: 0;
        //font-weight: 700;
    }
    .required-lable::after {
        font-size: 12px;
        display: inline-block;
        // margin: 0 0 0 20px;
        // padding: 3px 10px;
        line-height: 28px;
        content: "必須";
        color: #fff;
        border-radius: 0;
        background: #dd821a;

        width: 34px;
        text-align: center;
    }
    .any-lable {
        width: 40px;
        margin-right: 12px;
        margin-bottom: 0;
        font-weight: 700;
    }
    .any-lable::after {
        font-size: 12px;
        display: inline-block;
        // margin: 0 0 0 20px;
        // padding: 3px 10px;
        line-height: 28px;
        content: "任意";
        color: #fff;
        border-radius: 0;
        background: #7a9fee;

        width: 34px;
        text-align: center;
    }
    .input-date {
        width: 300px;
        margin-right: 20px;
        margin-bottom: 10px;
    }

    .input-time {
        width: 200px;
        margin-right: 20px;
    }
    .input-label {
        width: 150px;
    }
    .check-inline {
        display: inline-block;
        margin-right: 8px;
    }
    .ELearning-guide-line .contents-area {
        padding: 0;

        li {
            list-style: none;
            display: flex;
            justify-content: space-between;
        }
    }

    .step {
        width: 40px;
        position: relative;

        &:after {
            content: "";
            display: block;
            width: 4px;
            height: 100%;
            background: #eee;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
        }
    }

    .contents-area > li:nth-of-type(4) .step:after {
        display: none;
    }

    .step_box {
        width: 100%;
        height: 154px;
        background: #4d7aba;
        position: relative;
        color: #fff;
        z-index: 2;
        border-radius: 4px;
        /* background: #fdfce2; */
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);

        .step_head,
        .num {
            line-height: 1em;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        .step_head {
            font-size: 15px;
            top: 10px;
            text-align: center;
        }

        .num {
            font-size: 22px;
            bottom: 10px;
        }
    }

    .step_contents {
        width: calc(100% - 64px);
        margin-bottom: 32px;

        > div {
            background: #f2f7fa;
            padding: 20px 25px;
        }

        h3 {
            font-size: 18px;
            color: #4d7aba;
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 0.5em;
        }

        .white_cont {
            background: #fff;
            margin: 20px 0;
            padding: 20px 24px;

            h4 {
                font-size: 16px;
                position: relative;
                padding-left: 20px;
                margin-bottom: 20px;

                &:before {
                    content: "■";
                    font-size: 10px;
                    color: #4d7aba;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            ol {
                padding: 0;
                padding-left: 1.4em;
            }

            li {
                list-style: none;
                font-size: 16px;
                display: block;
                text-indent: -0.7em;
                margin-top: 0.5em;

                &:before {
                    display: inline-block;
                    font-size: 16px;
                    color: #4d7aba;
                    margin-right: 0.5em;
                    font-weight: bold;
                }

                &:nth-of-type(1):before {
                    content: "1.";
                }

                &:nth-of-type(2):before {
                    content: "2.";
                }

                &:nth-of-type(3):before {
                    content: "3.";
                }

                &:nth-of-type(4):before {
                    content: "4.";
                }
            }
        }
    }

    @media (max-width: 991px) {
        .step {
            width: 40px;

            &:after {
                width: 3px;
            }
        }

        .step_box {
            height: 80px;

            .step_head {
                font-size: 11px;
                top: 6px;
            }

            .num {
                font-size: 18px;
                bottom: 6px;
            }
        }

        .step_contents {
            width: calc(100% - 48px);
            margin-bottom: 36px;

            > div {
                padding: 16px 12px 2px;
            }

            h3 {
                font-size: 16px;
                color: #4d7aba;
                margin-bottom: 20px;
            }

            p {
                font-size: 14px;
                margin-bottom: 1em;
            }

            .white_cont {
                font-size: 14px;
                margin: 16px 0;
                padding: 16px 12px;

                h4 {
                    font-size: 14px;
                }

                li {
                    font-size: 14px;

                    &:before {
                        font-size: 14px;
                    }

                    margin-top: 1.2em;
                }
            }
        }
    }
}
