//
// HomeCarousel styles
//
.home-user-top {
    padding: 100px 20px 50px 20px;
    .section-title-block {
        margin: 0;
        .section-title {
            margin: 0;
            color: #367ba7;
            font-size: 30px;
            font-weight: 700;
            text-align: left;
            padding-bottom: 20px;
            &::after {
                content: "";
                display: block;
                width: 40px;
                height: 2px;
                border-bottom: 4px solid #367ba7;
                margin: 20px 0;
            }
        }
    }
}

.home-top {
    .wrapper {
        position: relative;
    }

    .youtube-png {
        height: 586px;
        cursor: pointer;
        img {
            height: 574px;
        }
    }

    .youtube-png-area {
        position: relative;
        cursor: pointer;

        .yotube-png-text-area {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .title-area {
                font-size: 36px;
            }

            .contents-area {
                width: 440px;
                margin: 0 auto;
            }

            .youtube-btn-image {
                width: 100px;
                margin: 28px auto;

                img {
                    width: 100px;
                }
            }

            p {
                margin-bottom: 0;
            }

            .title-area,
            .contents {
                color: white;
            }
        }
    }

    .slides-area {
        position: relative;
        height: 585px;
        .slides {
            visibility: hidden;
            position: absolute;
        }

        .slides.active {
            visibility: visible;
            overflow: hidden;
        }

        .right-to-left-anim {
            animation-name: right-to-left-anim;
            animation-duration: 0.5s;
        }

        .left-to-right-anim {
            animation-name: left-to-right-anim;
            animation-duration: 0.5s;
        }

        @keyframes left-to-right-anim {
            from {
                transform: translateX(-100%);
            }
            to {
                transform: translateX(0);
            }
        }

        @keyframes right-to-left-anim {
            from {
                transform: translateX(100%);
            }
            to {
                transform: translateX(0);
            }
        }
    }

    .indicators {
        display: flex;
        list-style: none;
        margin-top: 10px;
        justify-content: center;

        .active {
            background: #000;
        }

        li {
            height: 10px;
            width: 10px;
            background: #ddd;
            border-radius: 50%;
            margin-right: 5px;
            cursor: pointer;
            opacity: 0.7;
            margin: 0 6px;
        }
    }
}

@media (max-width: 768px) {
    .home-top {
        .slides-area {
            height: 455px;
        }

        .youtube-png {
            height: 455px;
            margin-bottom: 253px;
            img {
                height: 100%;
            }
        }

        .youtube-png-area {
            .yotube-png-text-area {
                top: 32%;
                left: 36%;
            }
        }

        .slides-area {
            height: 465px;
        }

        .indicators {
            padding: 0;
        }
    }
}

@media (max-width: 501px) {
    .home-top {
        .youtube-png {
            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .youtube-png-area {
            .yotube-png-text-area {
                width: 360px;
                top: 130px;
                left: 60px;
                transform: none;

                .title-area {
                    font-size: 24px;
                }

                .youtube-btn-image {
                    width: 80px;

                    img {
                        width: 80px;
                    }
                }

                .contents-area {
                    p {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media (max-width: 391px) {
    .home-top {
        .youtube-png-area {
            .yotube-png-text-area {
                width: 300px;
                top: 130px;
                left: 48px;
                .title-area {
                    font-size: 20px;
                }
                .youtube-btn-image {
                    width: 60px;

                    img {
                        width: 60px;
                    }
                }
                .contents-area {
                    width: 324px;
                }
            }
        }
    }
}

@media (min-width: 769px) {
    .home-top {
        width: 1300px;
        margin: 0 auto;
        .vciso-logo {
            margin-bottom: 35px;
        }
        .buttons-list {
            width: 470px;
        }
        .home-mv-1 {
            margin-top: 100px;
            display: block;
        }
        .buttons-list {
            width: 470px;
            .btn {
                width: 220px;
                height: 56px;
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .home-mv-2 {
        display: none;
    }
    .home-top {
        .vciso-logo {
            margin-bottom: 70px;
        }
        .top-text {
            font-size: 12px;
        }
        .buttons-list {
            .btn {
                width: 100%;
            }
        }
    }
    .home-user-top {
        padding: 80px 20px;
        .section-title-block {
            .section-title {
                font-size: 22px;
            }
        }
    }
}
