#tab-container .nav-link {
    font-size: 1.2rem;
}

#tab-container .tab-content {
    width: 100%
}

#tab-container .nav-link.active {
    color: #525e80 !important;
    font-weight: bold;
    border-bottom: 3px solid #525e80 !important;
    border-color: #fff #fff #525e80 !important;
  }
  
  #tab-container .nav-link:hover {
    border-color: #fff #fff #dee2e6 !important;
  }
  
  #tab-container .nav-link.active:hover {
    border-color: #fff #fff #525e80 !important;
  }
  
  #tab-container .nav-link {
    color: black;
    border-bottom: 1px solid #dee2e6 !important;
  }
  
  #tab-container .nav-tabs {
    border-bottom: transparent !important;
  }

@media (max-width: 786px) {
    .fill-tab-sm {
        height: calc(100vh - (12rem + 66px + 8px + 15vh + 30px + 56.38px + 38.39px))
    }
}

@media (min-width: 786px) {
    .fill-tab-md {
        height: calc(100vh - (12rem + 66px + 66.8px + 8px + 15vh + 30px + 56.38px + 38.39px))
    }
}

@media (min-width: 1200px) {
    .fill-tab-xl {
        height: calc(100vh - (12rem + 66px + 66.8px + 8px + 15vh + 30px + 56.38px + 38.39px))
    }
}