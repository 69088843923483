//
// DocTemplate styles
//

.DocTemplate {
    /*********************************************************						
共通
**********************************************************/

    #header {
        z-index: 1000;
    }

    #main {
        margin: 120px 0 80px;
    }

    section,
    .content_title {
        max-width: 1004px;
        margin: 0 auto;
    }

    @media (max-width: 1279px) {
        #main {
            margin: 50px 0 60px;
        }
    }

    @media (max-width: 991px) {
        .box {
            padding: 0 15px;
        }
    }

    /*下層見出し*/

    .under_title {
        background: #f2f7fa;
        padding: 25px 0;

        h1 {
            font-size: 34px;
            margin-bottom: 0;
        }
    }

    @media (max-width: 991px) {
        .under_title {
            // padding: 40px 0;

            h1 {
                font-size: 18px;
            }
        }
    }

    /*パンくず*/

    .vc_breadcrumb {
        padding-top: 12px;
        margin-bottom: 70px;

        ol {
            display: flex;
            list-style: none;
            padding: 0;

            li {
                font-size: 12px;
                color: #666;
                position: relative;
                padding-right: 32px;

                &:before,
                &:after {
                    content: "";
                    display: block;
                    width: 7px;
                    height: 1px;
                    background: #ccc;
                    position: absolute;
                    right: 12px;
                }

                &:before {
                    transform: rotate(45deg);
                    top: calc(50% - 2px);
                }

                &:after {
                    transform: rotate(-45deg);
                    top: calc(50% + 2px);
                }

                &.active {
                    padding: 0;

                    &:before,
                    &:after {
                        display: none;
                    }
                }

                a {
                    color: #4d7aba;
                    text-decoration: none;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .vc_breadcrumb {
            margin-bottom: 40px;
        }
    }

    @media (max-width: 430px) {
        .vc_breadcrumb ol li {
            font-size: 10px;
        }
    }

    /*本文*/

    .content_title {
        margin: 0 auto;
        padding: 70px 0 30px;

        &.fst_text {
            padding-top: 0;
        }

        div {
            font-size: 24px;
            line-height: 1.3em;
            padding-left: 14px;
            border-left: solid 6px #4d7aba;
        }
    }

    @media (max-width: 991px) {
        .content_title {
            padding: 40px 0 20px;

            div {
                font-size: 20px;
            }
        }
    }

    /*table*/

    .table {
        border-top: solid 1px rgb(179, 179, 179);
        border-bottom: solid 1px rgb(179, 179, 179);
        margin-bottom: 80px;

        tr {
            background: none;
        }

        th,
        td {
            font-size: 16px;
            vertical-align: middle;
            text-align: left !important;
            box-shadow: none;
        }

        th {
            width: 33.33%;
            border-right: none;
            background: #f2f7fa;
            padding: 30px 20px;
        }

        td {
            width: 66.66%;
            border-left: none;
            background: #fff;
            padding: 30px 40px;
        }
    }

    @media (max-width: 991px) {
        .table {
            display: block;
            width: 100%;
            border: none;

            tbody,
            tr,
            th,
            td {
                display: block;
                width: 100%;
                border: none;
            }

            border-bottom: solid 1px rgb(179, 179, 179);
            margin-bottom: 70px;

            th,
            td {
                font-size: 14px;
                padding: 14px 20px;
            }

            th {
                border-top: solid 1px rgb(179, 179, 179);
            }
        }
    }

    /*btn*/

    .template-customize-area .btn {
        display: block;
        width: 350px;
        font-size: 16px;
        color: #fff;
        background: #4d7aba;
        border-radius: 60px;
        position: relative;
        border: none;
        opacity: 1;
        margin: 0 auto;
        padding: 20px 0;

        &:before {
            content: "";
            width: 12px;
            height: 12px;
            // background: url("../assets/ac_arrow_on.svg") no-repeat center;
            background-size: 100% auto;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
        }
    }

    @media (max-width: 390px) {
        .template-customize-area .btn {
            width: 100%;
        }
    }

    @media (max-width: 360px) {
        .template-customize-area .btn:before {
            display: none;
        }
    }

    /*モーダル*/

    .modal-backdrop,
    .modal {
        z-index: 100002;
    }

    .modal-dialog {
        .modal-body {
            text-align: center;
        }

        .btn-primary {
            display: block;
            width: 270px;
            font-size: 14px;
            color: #fff;
            border-color: #4d7aba;
            background: #4d7aba;
            border-radius: 50px;
            position: relative;
            margin: 1rem auto;
            padding: 10px 0 !important;

            &:before {
                content: "";
                width: 12px;
                height: 12px;
                // background: url("../assets/ac_arrow_on.svg") no-repeat center;
                background-size: 100% auto;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }

    /*form*/

    .form-control,
    .form-select {
        border-radius: 3px;
        padding: 0.8rem 0.75rem;
    }

    /*********************************************************						
文書テンプレート
**********************************************************/

    .sub_title {
        font-size: 18px;
        color: #4d7aba;
        margin-bottom: 24px;
    }

    @media (max-width: 991px) {
        .sub_title {
            font-size: 16px;
        }
    }

    /*セキュリティポリシー*/

    .download-area {
        margin-bottom: 40px;

        > div {
            padding: 10px 5px 0 10px;

            // &:first-of-type {
            //     padding: 0 10px 0 0;
            // }

            // &:last-of-type {
            //     padding: 0 0 0 10px;
            // }
        }

        .dl_list {
            height: 100%;
            border: solid 1px #ccc;
            position: relative;
            

            h3,
            p {
                font-size: 16px;
            }

            h3 {
                text-align: center;
                background: #f2f7fa;
                padding: 15px;
                margin-bottom: 0;
            }

            div {
                padding: 25px 15px 80px;
            }
        }

        .btn-outline-primary {
            display: block;
            width: 188px;
            font-size: 14px;
            border-color: #4d7aba;
            border-radius: 50px;
            margin: 0 auto;
            padding: 10px 0;
            position: absolute;
            left: 50%;
            bottom: 20px;
            transform: translateX(-50%);

            &:before {
                content: "";
                width: 12px;
                height: 12px;
                // background: url("../assets/ac_arrow.svg") no-repeat center;
                background-size: 100% auto;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
            }

            &:hover {
                color: #fff;
                background-color: #4d7aba;

                &:before {
                    //      background-image: url("../assets/ac_arrow_on.svg");
                }
            }
        }
    }

    .template-customize-area p {
        margin-bottom: 40px;
    }

    @media (max-width: 991px) {
        .doc_lede,
        .download-area .dl_list p {
            font-size: 14px;
            line-height: 1.5em;
        }

        .template-customize-area p {
            font-size: 14px;
        }
    }

    @media (max-width: 768px) {
        .download-area > div {
            padding: 0;
            margin-bottom: 20px;

            &:nth-of-type(1),
            &:nth-of-type(3) {
                padding: 0;
                margin-bottom: 20px;
            }
        }
    }
}
