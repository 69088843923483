//
// SubAccountSignup styles
//

.SubAccountSignup {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-self: center;
    font-size: 16px;

    input {
        max-width: 558px;
        font-size: 16px;
        // border: 1px solid #ccc;
        border: solid 1px #d1cbbb;
        border-radius: 4px;
        background: #fdfce2;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }
    label {
        width: 150px;
        line-height: 36px;
        margin-bottom: 0;
        //font-weight: 700;
        font-size: 16px;
        // background-color: #edebe4;
    }
    .form-area {
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        width: 720px;
    }
    .button-area {
        display: flex;
        justify-content: right;
    }
    .button-area-2 {
       // display: flex;
        text-align: center;
    }
    .button-add {
        width: 120px;
        align-self: center;
        background-color: #4383E1;
        margin-bottom: 4px;
    }
    .button-align {
        text-align: center;
    }
    .button-size{
        width: 150px;
    }
    .edit-form-area {
        margin-bottom: 12px;
        height: 52px;
        .input-lastName {
            width: 214px;
            padding-right: 5px;
        }
        .input-firstName {
            width: 214px;
        }
    }
    .required-lable {
        width: 40px;
        //margin-right: 12px;
        margin-bottom: 0;
        //font-weight: 700;
    }
    .required-lable::after {
        font-size: 12px;
        display: inline-block;
        // margin: 0 0 0 20px;
        // padding: 3px 10px;
        content: "必須";
        color: #fff;
        border-radius: 0;
        background: #dd821a;

        width: 34px;
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        padding: 0 30px;

        input {
            margin-top: 10px;
        }
        .button-area-2{
            margin-top: 50px;
        }
        .required-lable{
            margin-left: 160px;
        }
        .form-area {
            width: 350px;
        }
        .modal-area {
            width: 280px;
        }
    }
}
