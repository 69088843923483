//
// Incident styles
//

.Incident {
    #header {
        z-index: 1000;
    }

    #main {
        margin: 120px 0 80px;
    }

    section,
    .content_title {
        max-width: 1004px;
        margin: 0 auto;
    }

    @media (max-width: 1279px) {
        #main {
            margin: 50px 0 60px;
        }
    }

    @media (max-width: 991px) {
        .box {
            padding: 0 15px;
        }
    }

    /*下層見出し*/

    .under_title {
        background: #f2f7fa;
        padding: 25px 0;

        h1 {
            font-size: 34px;
            margin-bottom: 0;
        }
    }

    @media (max-width: 991px) {
        .under_title {
            // padding: 40px 0;

            h1 {
                font-size: 18px;
            }
        }
    }

    /*パンくず*/

    .vc_breadcrumb {
        padding-top: 12px;
        margin-bottom: 70px;

        ol {
            display: flex;
            list-style: none;
            padding: 0;

            li {
                font-size: 12px;
                color: #666;
                position: relative;
                padding-right: 32px;

                &:before,
                &:after {
                    content: "";
                    display: block;
                    width: 7px;
                    height: 1px;
                    background: #ccc;
                    position: absolute;
                    right: 12px;
                }

                &:before {
                    transform: rotate(45deg);
                    top: calc(50% - 2px);
                }

                &:after {
                    transform: rotate(-45deg);
                    top: calc(50% + 2px);
                }

                &.active {
                    padding: 0;

                    &:before,
                    &:after {
                        display: none;
                    }
                }

                a {
                    color: #4d7aba;
                    text-decoration: none;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .vc_breadcrumb {
            margin-bottom: 40px;
        }
    }

    @media (max-width: 430px) {
        .vc_breadcrumb ol li {
            font-size: 10px;
        }
    }

    /*本文*/

    .content_title {
        margin: 0 auto;
        padding: 70px 0 30px;

        &.fst_text {
            padding-top: 0;
        }

        div {
            font-size: 24px;
            line-height: 1.3em;
            padding-left: 14px;
            border-left: solid 6px #4d7aba;
        }
    }

    @media (max-width: 991px) {
        .content_title {
            padding: 80px 0 20px;

            div {
                font-size: 20px;
            }
        }
    }

    /*table*/

    .table {
        border: solid 0.2px rgb(179, 179, 179);
        // border-bottom: solid 1px rgb(179, 179, 179);
        // border-top: solid 1px rgb(179, 179, 179);
        // margin-bottom: 80px;
        --bs-table-hover-bg: #abe1fa;
    

        // thead {
        //     tr {
        //         position: sticky;
        //         top: 0;
        //     }
        // }

        tr {
            background: #f2f7fa;
        }

        th,
        td {
            font-size: 16px;
            vertical-align: middle;
            // text-align: left !important;
            // box-shadow: none;
            background: #f2f7fa;
        }

        th {
            width: 10%;
            // border-right: none;
            background: #f2f7fa;
            padding: 15px 20px;
            text-align: left;
        }

        .td-1 {
            width: 5%;
            // border-left: none;
            background: #fff;
            padding: 15px 25px;
            text-align: left;
        }
        .td-2 {
            width: 25%;
            // border-left: none;
            background: #fff;
            padding: 15px 25px;
            text-align: left;
        }
        .td-3 {
            width: 35%;
            // border-left: none;
            background: #fff;
            padding: 15px 25px;
            text-align: left;
        }
        .td-4 {
            width: 25%;
            // border-left: none;
            background: #fff;
            padding: 15px 25px;
            text-align: left;
        }
    }
    tr:hover {
        background-color: #f2f7fa;
    }

    @media (max-width: 991px) {
        .table {
            // display: block;
            // width: 100%;
            // // border: none;

            // thead,
            // tbody,
            // tr,
            // th,
            // td {
            //     display: block;
            //     width: 100%;
            //     border: none;
            // }

            // // border-bottom: solid 1px rgb(179, 179, 179);
            // margin-bottom: 70px;

            // th,
            // td {
            //     font-size: 14px;
            //     padding: 14px 20px;
            // }

            // th {
            //     // border-top: solid 1px rgb(179, 179, 179);
            // }
        }
        .btn-margin {
            margin-bottom: 5px !important;
        }
    }

    /*btn*/

    .template-customize-area .btn {
        display: block;
        width: 350px;
        font-size: 16px;
        color: #fff;
        background: #4d7aba;
        border-radius: 60px;
        position: relative;
        border: none;
        opacity: 1;
        margin: 0 auto;
        padding: 20px 0;

        &:before {
            content: "";
            width: 12px;
            height: 12px;
            // background: url("../assets/ac_arrow_on.svg") no-repeat center;
            background-size: 100% auto;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
        }
    }

    @media (max-width: 390px) {
        .template-customize-area .btn {
            // width: 100%;
        }
    }

    @media (max-width: 360px) {
        .template-customize-area .btn:before {
            display: none;
        }
    }

    /*モーダル*/

    .modal-backdrop,
    .modal {
        z-index: 100002;
    }

    .modal-dialog {
        .modal-body {
            text-align: center;
        }

        .btn-primary {
            display: block;
            width: 270px;
            font-size: 14px;
            color: #fff;
            border-color: #4d7aba;
            background: #4d7aba;
            border-radius: 50px;
            position: relative;
            margin: 1rem auto;
            padding: 10px 0 !important;

            &:before {
                content: "";
                width: 12px;
                height: 12px;
                //background: url("../assets/ac_arrow_on.svg") no-repeat center;
                background-size: 100% auto;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }

    /*form*/

    .form-control,
    .form-select {
        border-radius: 3px;
        padding: 0.8rem 0.75rem;
    }

    .file-uploader {
        label {
            border: none;
        }

        .file_btn.disabled {
            background-color: rgba(77, 122, 186, 0.65);
            cursor: default;
            border: none;
        }
    }

    /*********************************************************						
  合同メール訓練詳細
  **********************************************************/

    .sub_title {
        font-size: 18px;
        color: #4d7aba;
        margin-bottom: 24px;
    }

    @media (max-width: 991px) {
        .sub_title {
            font-size: 16px;
        }
    }

    .email_method_list {
        padding: 0;

        li {
            list-style: none;
            margin-top: 60px;

            &:first-of-type {
                margin-top: 0;
            }

            p {
                margin-bottom: 40px;
            }

            .row {
                align-items: center;
            }

            .btn-primary {
                display: block;
                width: 270px;
                font-size: 16px;
                color: #fff;
                border-color: #4d7aba;
                background: #4d7aba;
                border-radius: 50px;
                position: relative;
                margin: 0 auto;
                padding: 14px 0;

                &:before {
                    content: "";
                    width: 12px;
                    height: 12px;
                    // background: url("../assets/ac_arrow_on.svg") no-repeat
                    //     center;
                    background-size: 100% auto;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%) rotate(90deg);
                }
            }
        }
    }

    .e_file_area {
        margin-bottom: 40px;

        label {
            width: 100%;
            display: flex;
            justify-content: space-between;

            > div {
                &:nth-of-type(1) {
                    width: 152px;
                }

                &:nth-of-type(2) {
                    width: calc(100% - 240px);
                }
            }
        }

        input[type="file"] {
            display: none;
        }

        .file_btn {
            display: block;
            width: 200px;
            font-size: 16px;
            text-align: center;
            color: #fff;
            border-color: #4d7aba;
            background: #4d7aba;
            border-radius: 50px;
            position: relative;
            margin: 0 auto;
            padding: 14px 0;
        }
    }

    .email_method_list .template-customize-area .btn {
        background-color: rgba(77, 122, 186, 0.65);
    }

    .email_method_list .template-customize-area .active {
        background-color: #4d7aba;
    }

    @media (max-width: 991px) {
        .email_method_list li {
            .row > div:first-child,
            p {
                font-size: 14px;
            }

            .btn-primary {
                margin: 0;
            }
        }
        .template-customize-area .btn {
            .btn-margin {
                margin-bottom: 4px !important;
            }
        }
    }

    @media (max-width: 768px) {
        .email_method_list li {
            &:first-child .row > div:first-child {
                margin-bottom: 20px;
            }

            .btn-primary {
                width: 100%;
            }
        }

        .e_file_area {
            label {
                display: block;

                > div {
                    &:nth-of-type(1),
                    &:nth-of-type(2) {
                        width: 100%;
                    }
                }
            }

            .file_btn {
                padding: 10px 0;
                margin: 0 0 20px;
            }
        }
    }

    .incident-date {
        width: 300px;
        margin-right: 20px;
    }

    .incident-time {
        width: 150px;
        margin-right: 20px;
    }
    .incident-label {
        width: 150px;
    }
}
