// stylelint-disable custom-property-empty-line-before

//
// Base styles
//

.btn {
  // scss-docs-start btn-css-vars
  --#{$prefix}btn-padding-x: #{$btn-padding-x};
  --#{$prefix}btn-padding-y: #{$btn-padding-y};
  --#{$prefix}btn-font-family: #{$btn-font-family};
  @include rfs($btn-font-size, --#{$prefix}btn-font-size);
  --#{$prefix}btn-font-weight: #{$btn-font-weight};
  --#{$prefix}btn-line-height: #{$btn-line-height};
  --#{$prefix}btn-color: #{$body-color};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-width: #{$btn-border-width};
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-border-radius: #{$btn-border-radius};
  --#{$prefix}btn-box-shadow: #{$btn-box-shadow};
  --#{$prefix}btn-disabled-opacity: #{$btn-disabled-opacity};
  --#{$prefix}btn-focus-box-shadow: 0 0 0 #{$btn-focus-width} rgba(var(--#{$prefix}btn-focus-shadow-rgb), .5);
  // scss-docs-end btn-css-vars

  display: inline-block;
  padding: var(--#{$prefix}btn-padding-y) var(--#{$prefix}btn-padding-x);
  font-family: var(--#{$prefix}btn-font-family);
  @include font-size(var(--#{$prefix}btn-font-size));
  font-weight: var(--#{$prefix}btn-font-weight);
  line-height: var(--#{$prefix}btn-line-height);
  color: var(--#{$prefix}btn-color);
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  border: var(--#{$prefix}btn-border-width) solid var(--#{$prefix}btn-border-color);
  @include border-radius(var(--#{$prefix}btn-border-radius));
  @include gradient-bg(var(--#{$prefix}btn-bg));
  @include box-shadow(var(--#{$prefix}btn-box-shadow));
  @include transition($btn-transition);

  &:hover {
    color: var(--#{$prefix}btn-hover-color);
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: var(--#{$prefix}btn-hover-bg);
    border-color: var(--#{$prefix}btn-hover-border-color);
  }

  .btn-check:focus + &,
  &:focus {
    color: var(--#{$prefix}btn-hover-color);
    @include gradient-bg(var(--#{$prefix}btn-hover-bg));
    border-color: var(--#{$prefix}btn-hover-border-color);
    outline: 0;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: var(--#{$prefix}btn-box-shadow), var(--#{$prefix}btn-focus-box-shadow);
    } @else {
      box-shadow: var(--#{$prefix}btn-focus-box-shadow);
    }
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  &.show {
    color: var(--#{$prefix}btn-active-color);
    background-color: var(--#{$prefix}btn-active-bg);
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: var(--#{$prefix}btn-active-border-color);
    @include box-shadow(var(--#{$prefix}btn-active-shadow));

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: var(--#{$prefix}btn-active-shadow), var(--#{$prefix}btn-focus-box-shadow);
      } @else {
        box-shadow: var(--#{$prefix}btn-focus-box-shadow);
      }
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    color: var(--#{$prefix}btn-disabled-color);
    pointer-events: none;
    background-color: var(--#{$prefix}btn-disabled-bg);
    background-image: if($enable-gradients, none, null);
    border-color: var(--#{$prefix}btn-disabled-border-color);
    opacity: var(--#{$prefix}btn-disabled-opacity);
    @include box-shadow(none);
  }
}


//
// Alternate buttons
//

// scss-docs-start btn-variant-loops
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}
// scss-docs-end btn-variant-loops


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  --#{$prefix}btn-font-weight: #{$font-weight-normal};
  --#{$prefix}btn-color: #{$btn-link-color};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-hover-color: #{$btn-link-hover-color};
  --#{$prefix}btn-hover-border-color: transparent;
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-disabled-color: #{$btn-link-disabled-color};
  --#{$prefix}btn-disabled-border-color: transparent;
  --#{$prefix}btn-box-shadow: none;

  text-decoration: $link-decoration;

  &:hover,
  &:focus {
    text-decoration: $link-hover-decoration;
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
}

.btn-blue {
  background-color: $btn-blue;
  border: none;
  padding: 5px 20px;
  &:hover {
    background-color: $btn-blue;
  }
}
.btn-blue-2 {
  background-color: $btn-blue-2;
  border: none;
  padding: 8px 20px;
  &:hover {
    background-color: $btn-blue-2;
  }
}
.btn-blue-md {
  background-color: $btn-blue-2;
  border: none;
  padding: $btn-md-padding;
  &:hover {
    background-color: $btn-blue-2;
  }
}
.btn-yellow {
  background-color: $btn-yellow;
  border: none;
  padding: 5px 20px;
  &:hover {
    background-color: $btn-yellow;
  }
}
.btn-green {
  background-color: $btn-green;
  border: none;
  padding: 5px 20px;
  &:hover {
    background-color: $btn-green;
  }
}
.btn-blue-border {
  background-color: $white;
  border: 1px solid $btn-blue;
  color: $btn-blue;
  padding: 5px 20px;
  &:hover {
    background-color: $white;
    color: $btn-blue;
  }
}

%btn-arrow {
  cursor: pointer;
  position: relative;
  text-decoration: none;
  border: none;
  background-color: transparent;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after{
    content: '';
    border: 0;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    display: inline-block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%) rotate(45deg);
  }
  &:hover {
    color: #fff;
    border: none;
  }
}
.btn-arrow {
  @extend %btn-arrow;
  &-blue {
    @extend %btn-arrow;
    background-color: $btn-dull-blue;
    width: 350px;
    height: 74px;
    font-size: 16px;
    line-height: 4.5em;
    &:hover {
      background-color: $btn-dull-blue;
    }
  }
  &-yellow {
    @extend %btn-arrow;
    background-color: $btn-yellow;
    &:hover {
      background-color: $btn-yellow;
    }
  }
  &-plan-lite {
    @extend %btn-arrow;
    background-color: $lite-plan-color;
    width: 100%;
    min-height: 46px;
    font-size: 14px;
    &:hover {
      background-color: $lite-plan-color;
    }
  }
  &-plan-standard {
    @extend %btn-arrow;
    background-color: $standard-plan-color;
    width: 100%;
    min-height: 46px;
    font-size: 14px;
    &:hover {
      background-color: $standard-plan-color;
    }
  }
  &-plan-premium {
    @extend %btn-arrow;
    background-color: $premium-plan-color;
    width: 100%;
    min-height: 46px;
    font-size: 14px;
    &:hover {
      background-color: $premium-plan-color;
    }
  }
}

@media screen and (max-width: 768px) {
  .btn-arrow-blue {
    width: 100%;
  }
}