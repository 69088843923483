//
// SetMfa styles
//

.SetMFA {
   // width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-self: center;

    input {
        width: 300px;
        font-size: 16px;
        // border: 1px solid #ccc;
        border: solid 1px #d1cbbb;
        border-radius: 4px;
        background: #fdfce2;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }
    .label-phone-name {
        width: 224px;
        line-height: 36px;
        margin-bottom: 0;
        //font-weight: 700;
        font-size: 16px;
        // background-color: #edebe4;
        text-align: left;
    }
    .button-register {
        width: 120px;
        align-self: center;
        background-color: #4383E1;
    }
    .form-area {
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        width: 700px;
    }

    .form-modal-area {
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        width: 400px;
    }
    .button-area {
        margin-top: 12px;
        display: flex;
        justify-content: right;
    }
    .button-confrim-area {
        margin-top: 12px;
        text-align: center;
    }
    .button-cancel {
        margin-top: 12px;
    }
    .button-register {
        margin-left: 50px;
    }
    .input-item {
        width: 200px;
        font-size: 16px;
        // border: 1px solid #ccc;
        border: solid 1px #d1cbbb;
        border-radius: 4px;
        background: #fdfce2;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }

    // .btn-blue-cancel{
    //     background-color: $btn-blue-2;
    //     border: none;
    //     text-align: center;
    //     &:hover {
    //       background-color: $btn-blue-2;
    //     }
    // }
    label {
        width: 200px;
        line-height: 36px;
        margin-bottom: 0;
        //font-weight: 700;
        font-size: 16px;
        // background-color: #edebe4;
    }

    .edit-form-area {
        margin-bottom: 12px;
        height: 52px;
        width: 500px;
    }
    
    // .modal-form-area {
    //     display: flex;
    //     justify-content: center;
    //     width: 600px;
    // }
    // .modal-area {
    //     border-radius: 0.5rem;
    //     display: flex;
    //     flex-direction: column;
    //     width: 600px;
    // }
    // .modal-button-area {
    //     margin-top: 12px;
    //     display: flex;
    //     justify-content: right;
    // }

    @media screen and (max-width: 768px) {
        padding: 0 30px;

        .form-area {
            width: 350px;
        }
        .modal-area {
            width: 280px;
        }
        .button-confrim-area{
            margin-top: 30px;
        }
    }
}
