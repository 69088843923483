.ChatService {
    /*********************************************************						
共通
**********************************************************/

    #header {
        z-index: 1000;
    }

    #main {
        margin: 120px 0 80px;
    }

    section,
    .content_title {
        max-width: 1004px;
        margin: 0 auto;
    }

    @media (max-width: 1279px) {
        #main {
            margin: 50px 0 60px;
        }
    }

    @media (max-width: 991px) {
        .box {
            padding: 0 15px;
        }
    }

    /*下層見出し*/

    .under_title {
        background: #f2f7fa;
        padding: 25px 0;

        h1 {
            font-size: 34px;
            margin-bottom: 0;
        }
    }

    @media (max-width: 991px) {
        .under_title {
            // padding: 40px 0;

            h1 {
                font-size: 18px;
            }
        }
    }

    /*パンくず*/

    .vc_breadcrumb {
        padding-top: 12px;
        margin-bottom: 70px;

        ol {
            display: flex;
            list-style: none;
            padding: 0;

            li {
                font-size: 12px;
                color: #666;
                position: relative;
                padding-right: 32px;

                &:before,
                &:after {
                    content: "";
                    display: block;
                    width: 7px;
                    height: 1px;
                    background: #ccc;
                    position: absolute;
                    right: 12px;
                }

                &:before {
                    transform: rotate(45deg);
                    top: calc(50% - 2px);
                }

                &:after {
                    transform: rotate(-45deg);
                    top: calc(50% + 2px);
                }

                &.active {
                    padding: 0;

                    &:before,
                    &:after {
                        display: none;
                    }
                }

                a {
                    color: #4d7aba;
                    text-decoration: none;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .vc_breadcrumb {
            margin-bottom: 40px;
        }
    }

    @media (max-width: 430px) {
        .vc_breadcrumb ol li {
            font-size: 10px;
        }
    }

    /*本文*/

    .content_title {
        margin: 0 auto;
        padding: 70px 0 30px;

        &.fst_text {
            padding-top: 0;
        }

        div {
            font-size: 24px;
            line-height: 1.3em;
            padding-left: 14px;
            border-left: solid 6px #4d7aba;
        }
    }

    @media (max-width: 991px) {
        .content_title {
            padding: 40px 0 20px;

            div {
                font-size: 20px;
            }
        }
    }

    /*table*/

    // .table {
    //     border-top: solid 1px rgb(179, 179, 179);
    //     border-bottom: solid 1px rgb(179, 179, 179);
    //     margin-bottom: 80px;

    //     tr {
    //         background: none;
    //     }

    //     th,
    //     td {
    //         font-size: 16px;
    //         vertical-align: middle;
    //         text-align: left !important;
    //         box-shadow: none;
    //     }

    //     th {
    //         width: 33.33%;
    //         border-right: none;
    //         background: #f2f7fa;
    //         padding: 30px 20px;
    //     }

    //     td {
    //         width: 66.66%;
    //         border-left: none;
    //         background: #fff;
    //         padding: 30px 40px;
    //     }
    // }

    // @media (max-width: 991px) {
    //     .table {
    //         display: block;
    //         width: 100%;
    //         border: none;

    //         tbody,
    //         tr,
    //         th,
    //         td {
    //             display: block;
    //             width: 100%;
    //             border: none;
    //         }

    //         border-bottom: solid 1px rgb(179, 179, 179);
    //         margin-bottom: 70px;

    //         th,
    //         td {
    //             font-size: 14px;
    //             padding: 14px 20px;
    //         }

    //         th {
    //             border-top: solid 1px rgb(179, 179, 179);
    //         }
    //     }
    // }

    /*btn*/

    .template-customize-area .btn {
        display: block;
        width: 350px;
        font-size: 14px;
        color: #fff;
        background: #4d7aba;
        border-radius: 60px;
        position: relative;
        border: none;
        opacity: 1;
        margin: 0 auto;
        padding: 20px 0;

        &:before {
            content: "";
            width: 12px;
            height: 12px;
            //background: url("../assets/ac_arrow_on.svg") no-repeat center;
            background-size: 100% auto;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
        }
    }

    @media (max-width: 390px) {
        .template-customize-area .btn {
            width: 100%;
        }
    }

    @media (max-width: 360px) {
        .template-customize-area .btn:before {
            display: none;
        }
    }

    /*モーダル*/

    .modal-backdrop,
    .modal {
        z-index: 100002;
    }

    .modal-dialog {
        .modal-body {
            text-align: center;
        }

        .btn-primary {
            display: block;
            width: 270px;
            font-size: 14px;
            color: #fff;
            border-color: #4d7aba;
            background: #4d7aba;
            border-radius: 50px;
            position: relative;
            margin: 1rem auto;
            padding: 10px 0 !important;

            &:before {
                content: "";
                width: 12px;
                height: 12px;
                //background: url("../assets/ac_arrow_on.svg") no-repeat center;
                background-size: 100% auto;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }

    /*form*/

    .form-control,
    .form-select {
        border-radius: 3px;
        padding: 0.8rem 0.75rem;
    }

    /*********************************************************						
よろづ相談
**********************************************************/

    .chat-link {
        margin-bottom: 20px;
        color: var(--bs-link-color);
        cursor: pointer;
        text-decoration: underline;
    }

    .under_title

    /*サイバーセキュリティ*/

    .vstack {
        height: 500px;
        // border: solid 1px #ccc;
    }

    #chatplusview {
        z-index: 1000;
    }

    #chat-window #chatplusview {
        &.chatplusview-modern #outline {
            box-shadow: 0 0 0 0;
            border-radius: 0;
            border: solid 1px #ccc;

            #chatpluscontent #chatplusheader {
                border-radius: 0;
                
            }
        }

        #outline #chatpluscontent #chatplusheader {
            background: #4d7aba !important;
            height: auto;
            padding: 12px 20px;
            // border: solid red;
        }
    }

    #chatplusview #outline #chatpluscontent #chatplusheader .profile span.name {
        font-size: 20px;
        margin-left: 0;
    }

    #chat-window
        #chatplusview.chatplusview-modern.front
        #outline
        #chatpluscontent {
        #chatplusheader .profile {
            position: static;
        }

        #body h2 {
            margin-bottom: 40px;

            &:before,
            &:after {
                content: "";
                display: table;
                clear: both;
            }

            .icon {
                width: 140px;
                height: auto;

                i:before {
                    font-size: 94px;
                }
            }

            .text {
                font-size: 16px;
                line-height: 1.5em;
            }
        }

        .form-group .btn {
            max-width: 350px;
            font-size: 16px;
            background: #4d7aba;
            border-radius: 60px;
            padding: 20px 0;
        }
    }

    @media (max-width: 991px) {
        #chat-window
            #chatplusview.chatplusview-modern.front
            #outline
            #chatpluscontent
            #body {
            padding: 24px 20px;
        }
        .chat-link {
            margin-left: 10px;
        }
        .ChatService {
            margin-left: 10px;
        }
    

        #chatplusview
            #outline
            #chatpluscontent
            #chatplusheader
            .profile
            span.name {
            font-size: 18px;
        }

        #chat-window
            #chatplusview.chatplusview-modern.front
            #outline
            #chatpluscontent
            #body
            h2
            .icon {
            width: 100%;
            float: none;
            margin-bottom: 20px;
        }
    }

    /*table*/

    .table {
        border-top: solid 1px rgb(179, 179, 179);
        border-bottom: solid 1px rgb(179, 179, 179);
        margin-bottom: 0;

        tr {
            background: none;
        }

        th,
        td {
            font-size: 16px;
            vertical-align: middle;
            text-align: left !important;
            box-shadow: none;
        }

        th {
            width: 33.33%;
            border-right: none;
            background: #f2f7fa;
            padding: 30px 20px;
        }

        td {
            width: 66.66%;
            border-left: none;
            background: #fff;
            padding: 30px 40px;
        }
    }

    @media (max-width: 991px) {
        .table {
            display: block;
            width: 100%;
            border: none;

            tbody,
            tr,
            th,
            td {
                display: block;
                width: 100%;
                border: none;
            }

            border-bottom: solid 1px rgb(179, 179, 179);

            th,
            td {
                font-size: 14px;
                padding: 14px 20px;
            }

            th {
                border-top: solid 1px rgb(179, 179, 179);
            }
        }
    }

    /*モーダル*/

    .modal {
        display: none;
        width: 1208px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .modal-body {
            padding-top: 80px;
            padding-bottom: 80px;

            .modal-close {
                width: 30px;
                height: 30px;
                position: absolute;
                right: 40px;
                top: 40px;
                cursor: pointer;

                &:before,
                &:after {
                    content: "";
                    display: block;
                    width: 40px;
                    height: 1px;
                    background: #ccc;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                }

                &:before {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }

                &:after {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }
        }

        .content_title {
            text-align: left;
        }
    }

    @media (max-width: 1258px) {
        .modal {
            width: calc(100% - 50px);
        }
    }

    @media (max-width: 991px) {
        .modal {
            &.show .modal-dialog {
                margin-left: 0;
                margin-right: 0;
            }

            .modal-body .modal-close {
                width: 24px;
                height: 24px;
                right: 24px;
                top: 24px;

                &:before,
                &:after {
                    width: 32px;
                }
            }
        }
    }

    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 1208px;
        }
    }

    /*よくある質問*/

    .sub_title {
        font-size: 18px;
        color: #4d7aba;
        margin-bottom: 24px;
    }

    .accordion-item {
        border-radius: 0;
        border: none;

        &:first-of-type {
            border-radius: 0;
            border: none;

            .accordion-button {
                border-radius: 0;
                border: none;
            }
        }

        &:last-of-type .accordion-button.collapsed {
            border-radius: 0;
            border: none;
        }
    }

    .accordion-header .accordion-button {
        background: #f5f5f5;
    }

    .accordion-item .accordion-button {
        position: relative;
        padding: 24px 50px 24px 50px;
    }

    .accordion-collapse .accordion-body {
        position: relative;
        padding: 24px 50px 24px 50px;
        border-bottom: solid 1px #ccc;
    }

    .accordion-item .accordion-button:before,
    .accordion-collapse .accordion-body:before {
        font-size: 18px;
        font-family: "Noto Sans";
        position: absolute;
    }

    .accordion-item .accordion-button:before {
        content: "Q";
        color: #4d7aba;
        left: 28px;
        top: 50%;
        transform: translateY(-50%);
    }

    .accordion-collapse .accordion-body:before {
        content: "A";
        color: #ffaa02;
        left: 28px;
        top: 50%;
        transform: translateY(-50%);
    }

    .accordion-item .accordion-button {
        &:after {
            content: "";
            width: 12px;
            height: 12px;
            //background: url("../assets/ac_arrow.svg") no-repeat center;
            background-size: 100% auto;
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%) rotate(0deg);
            transition: 0.5s;
        }

        &.collapsed:after {
            transform: translateY(-50%) rotate(180deg);
        }
    }

    @media (max-width: 991px) {
        .sub_title {
            font-size: 16px;
        }

        .accordion-header .accordion-button,
        .accordion-collapse .accordion-body {
            font-size: 14px;
            line-height: 1.5em;
        }

        .accordion-item .accordion-button {
            position: relative;
            padding: 16px 43px 16px 38px;
        }

        .accordion-collapse .accordion-body {
            position: relative;
            padding: 16px 43px 16px 38px;
            padding-right: 20px;
        }

        .accordion-item .accordion-button:before,
        .accordion-collapse .accordion-body:before {
            font-size: 16px;
            left: 14px;
            top: 16px;
            transform: translateY(0);
        }

        .accordion-item .accordion-button:after {
            right: 16px;
        }
    }
}
