//
// ELearning styles
//

.ELearning {
    /*********************************************************						
共通
**********************************************************/

    #header {
        z-index: 1000;
    }

    #main {
        margin: 120px 0 80px;
    }

    section,
    .content_title {
        max-width: 1004px;
        margin: 0 auto;
    }

    @media (max-width: 1279px) {
        #main {
            margin: 50px 0 60px;
        }
    }

    @media (max-width: 991px) {
        .box {
            padding: 0 15px;
        }
    }

    /*下層見出し*/

    .under_title {
        background: #f2f7fa;
        padding: 25px 0;

        h1 {
            font-size: 34px;
            margin-bottom: 0;
        }
    }

    @media (max-width: 991px) {
        .under_title {
            // padding: 40px 0;

            h1 {
                font-size: 18px;
            }
        }
    }

    .chat-link {
        margin-bottom: 20px;
        color: var(--bs-link-color);
        cursor: pointer;
        text-decoration: underline;
    }
    
    /*パンくず*/

    .vc_breadcrumb {
        padding-top: 12px;
        margin-bottom: 70px;

        ol {
            display: flex;
            list-style: none;
            padding: 0;

            li {
                font-size: 12px;
                color: #666;
                position: relative;
                padding-right: 32px;

                &:before,
                &:after {
                    content: "";
                    display: block;
                    width: 7px;
                    height: 1px;
                    background: #ccc;
                    position: absolute;
                    right: 12px;
                }

                &:before {
                    transform: rotate(45deg);
                    top: calc(50% - 2px);
                }

                &:after {
                    transform: rotate(-45deg);
                    top: calc(50% + 2px);
                }

                &.active {
                    padding: 0;

                    &:before,
                    &:after {
                        display: none;
                    }
                }

                a {
                    color: #4d7aba;
                    text-decoration: none;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .vc_breadcrumb {
            margin-bottom: 40px;
        }
    }

    @media (max-width: 430px) {
        .vc_breadcrumb ol li {
            font-size: 10px;
        }
    }

    /*本文*/

    .content_title {
        margin: 0 auto;
        padding: 70px 0 30px;

        &.fst_text {
            padding-top: 0;
        }

        div {
            font-size: 24px;
            line-height: 1.3em;
            padding-left: 14px;
            border-left: solid 6px #4d7aba;
        }
    }

    @media (max-width: 991px) {
        .content_title {
            padding: 40px 0 20px;

            div {
                font-size: 20px;
            }
        }
    }

    /*table*/

    .table {
        border-top: solid 1px rgb(179, 179, 179);
        border-bottom: solid 1px rgb(179, 179, 179);
        margin-bottom: 80px;

        tr {
            background: none;
        }

        th,
        td {
            font-size: 16px;
            vertical-align: middle;
            text-align: left !important;
            box-shadow: none;
        }

        th {
            width: 33.33%;
            border-right: none;
            background: #f2f7fa;
            padding: 30px 20px;
        }

        td {
            width: 66.66%;
            border-left: none;
            background: #fff;
            padding: 30px 40px;
        }
    }

    @media (max-width: 991px) {
        .table {
            display: block;
            width: 100%;
            border: none;

            tbody,
            tr,
            th,
            td {
                display: block;
                width: 100%;
                border: none;
            }

            border-bottom: solid 1px rgb(179, 179, 179);
            margin-bottom: 70px;

            th,
            td {
                font-size: 14px;
                padding: 14px 20px;
            }

            th {
                border-top: solid 1px rgb(179, 179, 179);
            }
        }
    }

    /*btn*/

    .template-customize-area .btn {
        display: block;
        width: 350px;
        font-size: 14px;
        color: #fff;
        background: #4d7aba;
        border-radius: 60px;
        position: relative;
        border: none;
        opacity: 1;
        margin: 0 auto;
        padding: 20px 0;

        &:before {
            content: "";
            width: 12px;
            height: 12px;
           // background: url("../assets/ac_arrow_on.svg") no-repeat center;
            background-size: 100% auto;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
        }
    }

    @media (max-width: 390px) {
        .template-customize-area .btn {
            width: 100%;
        }
    }

    @media (max-width: 360px) {
        .template-customize-area .btn:before {
            display: none;
        }
    }

    /*モーダル*/

    .modal-backdrop,
    .modal {
        z-index: 100002;
    }

    .modal-dialog {
        .modal-body {
            text-align: center;
        }

        .btn-primary {
            display: block;
            width: 270px;
            font-size: 14px;
            color: #fff;
            border-color: #4d7aba;
            background: #4d7aba;
            border-radius: 50px;
            position: relative;
            margin: 1rem auto;
            padding: 10px 0 !important;

            &:before {
                content: "";
                width: 12px;
                height: 12px;
                //background: url("../assets/ac_arrow_on.svg") no-repeat center;
                background-size: 100% auto;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }

    /*form*/

    .form-control,
    .form-select {
        border-radius: 3px;
        padding: 0.8rem 0.75rem;
    }

    /*********************************************************						
Eラーニング
**********************************************************/

    .ELearning-guide-line .contents-area {
        padding: 0;

        li {
            list-style: none;
            display: flex;
            justify-content: space-between;
        }
    }

    .step {
        width: 50px;
        position: relative;

        &:after {
            content: "";
            display: block;
            width: 4px;
            height: 100%;
            background: #eee;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
        }
    }

    .contents-area > li:nth-of-type(4) .step:after {
        display: none;
    }

    .step_box {
        width: 100%;
        height: 52px;
        background: #4d7aba;
        position: relative;
        color: #fff;
        z-index: 2;

        .step_head,
        .num {
            line-height: 1em;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        .step_head {
            font-size: 10px;
            top: 10px;
        }

        .num {
            font-size: 22px;
            bottom: 10px;
        }
    }

    .step_contents {
        width: calc(100% - 64px);
        margin-bottom: 32px;

        > div {
            background: #f2f7fa;
            padding: 20px 25px;
        }

        h3 {
            font-size: 18px;
            color: #4d7aba;
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 0.5em;
        }

        .white_cont {
            background: #fff;
            margin: 20px 0;
            padding: 20px 24px;

            h4 {
                font-size: 16px;
                position: relative;
                padding-left: 20px;
                margin-bottom: 20px;

                &:before {
                    content: "■";
                    font-size: 10px;
                    color: #4d7aba;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            ol {
                padding: 0;
                padding-left: 1.4em;
            }

            li {
                list-style: none;
                font-size: 16px;
                display: block;
                text-indent: -0.7em;
                margin-top: 0.5em;

                &:before {
                    display: inline-block;
                    font-size: 16px;
                    color: #4d7aba;
                    margin-right: 0.5em;
                    font-weight: bold;
                }

                &:nth-of-type(1):before {
                    content: "1.";
                }

                &:nth-of-type(2):before {
                    content: "2.";
                }

                &:nth-of-type(3):before {
                    content: "3.";
                }

                &:nth-of-type(4):before {
                    content: "4.";
                }
            }
            

            button {
                background: none!important;
                border: none;
                padding: 0!important;
                /*optional*/
                font-family: arial, sans-serif;
                /*input has OS specific font-family*/
                color: #0d6efd;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    @media (max-width: 991px) {
        .step {
            width: 40px;

            &:after {
                width: 3px;
            }
        }

        .step_box {
            height: 40px;

            .step_head {
                font-size: 9px;
                top: 6px;
            }

            .num {
                font-size: 18px;
                bottom: 6px;
            }
        }

        .step_contents {
            width: calc(100% - 48px);
            margin-bottom: 36px;

            > div {
                padding: 16px 12px 2px;
            }

            h3 {
                font-size: 16px;
                color: #4d7aba;
                margin-bottom: 20px;
            }

            p {
                font-size: 14px;
                margin-bottom: 1em;
            }

            .white_cont {
                font-size: 14px;
                margin: 16px 0;
                padding: 16px 12px;

                h4 {
                    font-size: 14px;
                }

                li {
                    font-size: 14px;

                    &:before {
                        font-size: 14px;
                    }

                    margin-top: 1.2em;
                }
            }
        }
    }
    // .ELearning-guide-line {
    //   width: 100%;

    //   .url-text {
    //     word-break: break-word;
    //   }

    //   .title-area {
    //     margin-bottom: 12px;
    //     border: 2px solid rgb(65, 105, 225);
    //     box-shadow: rgb(205 205 205) 5px 5px 5px;

    //     h3 {
    //       color: white;
    //     }
    //   }

    //   .content-layout {
    //     width: 50%;
    //     padding: 8px;
    //   }

    //   .content-left-1,
    //   .content-left-2,
    //   .content-left-3,
    //   .content-left-4 {
    //     border: 2px solid rgb(205, 205, 205);
    //     box-shadow: rgb(205 205 205) 5px 5px 5px;
    //     border-radius: 10px;
    //     margin-bottom: 20px;
    //     background-color: rgb(253, 249, 249);
    //   }

    //   .content-1,
    //   .content-2,
    //   .content-3,
    //   .content-4 {
    //     display: flex;
    //   }

    //   .content-left-3 {
    //     h4 {
    //       font-weight: bolder;
    //       color: #0062D1
    //     }

    //     ul {
    //       color: #0062D1
    //     }
    //   }

    //   .content-left-1,
    //   .content-left-2,
    //   .content-left-3,
    //   .content-left-4 {
    //     margin-right: 20px;
    //   }

    //   .content-right-1 {
    //     border: 2px solid rgb(205, 205, 205);
    //     box-shadow: rgb(205 205 205) 5px 5px 5px;
    //     // border-radius: 10px;
    //     margin-bottom: 20px;

    //     .content-url {
    //       margin-top: 20px;
    //     }
    //   }

    //   .content-area {
    //     h3 {
    //       color: white;
    //       background-color: rgb(65, 105, 205);
    //       padding: 8px;
    //       border: 1px solid black;
    //       border-radius: 8px;
    //     }
    //   }

    //   .content-arrow {
    //     margin-bottom: 40px;
    //   }

    //     .arrow_down {
    //       font-size: 50px;
    //       position: relative;
    //       width: 0.6em;
    //       height: 1em;
    //       background-color: rgb(65, 105, 205);
    //       margin-left: 30px;
    //     }

    //     /* 三角 */
    //     .arrow_down::before {
    //       position: absolute;
    //       top: 0.8em;
    //       left: -0.5em;
    //       width: 0;
    //       height: 0;
    //       content: "";
    //       border-top: 0.8em solid rgb(65, 105, 205);
    //       border-left: 0.8em solid transparent;
    //       border-right: 0.8em solid transparent;
    //     }

    // }

    // @media screen and (max-width: 768px) {
    //   padding: 0 30px;
    // }
}
