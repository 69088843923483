//
// Counseling styles
//

.Counseling {
    /*********************************************************						
共通
**********************************************************/

    #header {
        z-index: 1000;
    }

    #main {
        margin: 120px 0 80px;
    }

    section,
    .content_title {
        max-width: 1004px;
        margin: 0 auto;
    }

    @media (max-width: 1279px) {
        #main {
            margin: 50px 0 60px;
        }
    }

    @media (max-width: 991px) {
        .box {
            padding: 0 15px;
        }
    }

    /*下層見出し*/

    .under_title {
        background: #f2f7fa;
        padding: 50px 0;

        h1 {
            font-size: 34px;
            margin-bottom: 0;
        }
    }

    @media (max-width: 991px) {
        .under_title {
            padding: 25px 0;

            h1 {
                font-size: 18px;
            }
        }
    }

    .chat-link {
        margin-bottom: 20px;
        color: var(--bs-link-color);
        cursor: pointer;
        text-decoration: underline;
    }


    /*パンくず*/

    .vc_breadcrumb {
        padding-top: 12px;
        margin-bottom: 70px;

        ol {
            display: flex;
            list-style: none;
            padding: 0;

            li {
                font-size: 12px;
                color: #666;
                position: relative;
                padding-right: 32px;

                &:before,
                &:after {
                    content: "";
                    display: block;
                    width: 7px;
                    height: 1px;
                    background: #ccc;
                    position: absolute;
                    right: 12px;
                }

                &:before {
                    transform: rotate(45deg);
                    top: calc(50% - 2px);
                }

                &:after {
                    transform: rotate(-45deg);
                    top: calc(50% + 2px);
                }

                &.active {
                    padding: 0;

                    &:before,
                    &:after {
                        display: none;
                    }
                }

                a {
                    color: #4d7aba;
                    text-decoration: none;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .vc_breadcrumb {
            margin-bottom: 40px;
        }
    }

    @media (max-width: 430px) {
        .vc_breadcrumb ol li {
            font-size: 10px;
        }
    }

    /*本文*/

    .content_title {
        margin: 0 auto;
        padding: 70px 0 30px;

        &.fst_text {
            padding-top: 0;
        }

        div {
            font-size: 24px;
            line-height: 1.3em;
            padding-left: 14px;
            border-left: solid 6px #4d7aba;
        }
    }

    @media (max-width: 991px) {
        .content_title {
            padding: 80px 0 20px;

            div {
                font-size: 20px;
            }
        }
    }

    /*table*/

    .table {
        border-top: solid 1px rgb(179, 179, 179);
        border-bottom: solid 1px rgb(179, 179, 179);
        margin-bottom: 80px;

        tr {
            background: none;
        }

        th,
        td {
            font-size: 16px;
            vertical-align: middle;
            text-align: left !important;
            box-shadow: none;
        }

        th {
            width: 33.33%;
            border-right: none;
            background: #f2f7fa;
            padding: 30px 20px;
        }

        td {
            width: 66.66%;
            border-left: none;
            background: #fff;
            padding: 30px 40px;
        }
    }

    @media (max-width: 991px) {
        .table {
            display: block;
            width: 100%;
            border: none;

            tbody,
            tr,
            th,
            td {
                display: block;
                width: 100%;
                border: none;
            }

            border-bottom: solid 1px rgb(179, 179, 179);
            margin-bottom: 70px;

            th,
            td {
                font-size: 14px;
                padding: 14px 20px;
            }

            th {
                border-top: solid 1px rgb(179, 179, 179);
            }
        }
    }

    /*btn*/

    .template-customize-area .btn {
        display: block;
        width: 350px;
        font-size: 16px;
        color: #fff;
        background: #4d7aba;
        border-radius: 60px;
        position: relative;
        border: none;
        opacity: 1;
        margin: 0 auto;
        padding: 20px 0;

        &:before {
            content: "";
            width: 12px;
            height: 12px;
            // background: url("../../../assets/ac_arrow_on.svg") no-repeat center;
            background-size: 100% auto;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
        }
    }

    @media (max-width: 390px) {
        .template-customize-area .btn {
            // width: 100%;
        }
    }

    @media (max-width: 360px) {
        .template-customize-area .btn:before {
            display: none;
        }
    }

    /*モーダル*/

    .modal-backdrop,
    .modal {
        z-index: 100002;
    }

    .modal-dialog {
        .modal-body {
            text-align: center;
        }

        .btn-primary {
            display: block;
            width: 270px;
            font-size: 14px;
            color: #fff;
            border-color: #4d7aba;
            background: #4d7aba;
            border-radius: 50px;
            position: relative;
            margin: 1rem auto;
            padding: 10px 0 !important;

            &:before {
                content: "";
                width: 12px;
                height: 12px;
                background: url("../../../assets/ac_arrow_on.svg") no-repeat center;
                background-size: 100% auto;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }

    /*form*/

    .form-control,
    .form-select {
        border-radius: 3px;
        padding: 0.8rem 0.75rem;
    }

    /*********************************************************						
個別カウンセリング
**********************************************************/
    .counseling-nav {
        cursor: pointer;
    }

    .anchor_link {
        background: #f5f5f5;
        text-align: center;
        padding: 25px 0;
        margin: 60px 0 0;

        li {
            list-style: none;
            display: inline-block;
            background: url("../../../assets/anchor_arrow.svg") no-repeat left center;
            background-size: 18px auto;
            border-right: solid 1px #ccc;
            padding: 0 20px 0 25px;
            margin-left: 20px;

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                padding-right: 0;
                border-right: none;
            }

            a {
                color: #333;
                text-decoration: none;
            }
        }
    }

    .table {
        margin-bottom: 0;
    }

    .table_list {
        padding-left: 1.2em;
        margin: 0;

        li {
            margin-top: 1em;

            &:first-of-type {
                margin-top: 0;
            }
        }
    }

    section .customer_items {
        margin-top: 40px;

        &:first-of-type {
            margin-top: 0 !important;
        }
    }

    .customer_items {
        p {
            margin-bottom: 0;
        }

        ol {
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                margin-top: 0.3em;

                &:first-of-type {
                    margin-top: 0;
                }
            }
        }
    }

    .entry {
        label {
            font-weight: bold;
        }

        .entry_times {
            display: flex;
            margin-bottom: 40px;
        }

        .contract-date-input .form-control,
        .contract-time-input .form-select {
            width: 188px;
            border-radius: 3px;
            padding: 0.75rem 0.75rem;
        }

        .contract-date-input .form-control {
            background: url("../../../assets/calendar.svg") no-repeat right 15px
               center;
            margin-right: 16px;
        }

        .contract-info-textarea {
            margin-bottom: 40px;

            .form-control {
                border-radius: 0;
            }
        }
    }

    @media (max-width: 991px) {
        .btn-margin{
            margin-bottom: 10px !important;
        }
        .anchor_link {
            text-align: left;
            margin-top: 40px;
            padding: 20px 15px;

            li {
                display: block;
                padding-right: 0;
                border-right: none;
                margin: 20px 0 0;

                &:first-of-type {
                    margin-top: 0;
                }

                a {
                    font-size: 14px;
                }
            }
        }

        section .customer_items {
            margin-top: 50px;
        }

        .customer_items {
            .sub_title {
                margin-bottom: 20px;
            }

            p,
            ol li {
                font-size: 14px;
            }
        }

        .entry {
            label {
                font-size: 14px;
            }

            .entry_times {
                justify-content: space-between;
            }

            .contract-date-input,
            .contract-time-input {
                width: calc(50% - 8px);
            }

            .contract-date-input .form-control,
            .contract-time-input .form-select {
                width: 100%;
            }
        }
        .input-date {
            width: 148px !important;
        }
    
        .input-time {
            width: 148px !important;
           
        }
    }
    .input-date {
        width: 300px;
        margin-right: 20px;
        margin-bottom: 10px;
    }

    .input-time {
        width: 200px;
        margin-right: 20px;
    }
    .input-label {
        width: 150px;
    }
}
