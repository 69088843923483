//
// SetAccounts styles
//

.SetAccounts {
    .form-area {
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        width: 720px;
    }
    .button-area {
        display: flex;
        justify-content: right;
    }
    .button-add {
        width: 120px;
        align-self: center;
        background-color: #4383E1;
        margin-bottom: 4px;
    }
    .button-align {
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        padding: 0 30px;

        .form-area {
            width: 350px;
        }
        .modal-area {
            width: 280px;
        }
    }
}
