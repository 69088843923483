//
// EditUser styles
//

.EditUser {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-self: center;
    font-size: 16px;

    input {
        width: 440px;
        font-size: 16px;
        // border: 1px solid #ccc;
        border: solid 1px #d1cbbb;
        border-radius: 4px;
        background: #fdfce2;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }
    label {
        width: 224px;
        line-height: 36px;
        margin-bottom: 0;
        //font-weight: 700;
        font-size: 16px;
        // background-color: #edebe4;
    }
    .label-sub-title {
        width: 100%;
        background-color: #edebe4;
        font-size: 16px;
        font-weight: bold;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }
    .label-manager {
        width: 224px;
        line-height: 36px;
        margin-bottom: 0;
        //font-weight: 700;
        font-size: 16px;
        // background-color: #edebe4;
    }
    .required-lable {
        width: 40px;
        margin-right: 12px;
        margin-bottom: 0;
        //font-weight: 700;
    }
    .required-lable::after {
        font-size: 12px;
        display: inline-block;
        // margin: 0 0 0 20px;
        // padding: 3px 10px;
        content: "必須";
        color: #fff;
        border-radius: 0;
        background: #dd821a;

        width: 34px;
        text-align: center;
    }
    .any-lable {
        width: 40px;
        margin-right: 12px;
        margin-bottom: 0;
        font-weight: 700;
    }
    .any-lable::after {
        font-size: 12px;
        display: inline-block;
        // margin: 0 0 0 20px;
        // padding: 3px 10px;
        content: "任意";
        color: #fff;
        border-radius: 0;
        background: #7a9fee;

        width: 34px;
        text-align: center;
    }

    .input-post-code {
        width: 140px;
        font-size: 0.9rem;
        background: #fdfce2;
    }

    .button-post {
        width: 200px;
        margin-left: 98px;
    }
    .checkbox-area {
        width: 30px;
        height: 30px;
        align-self: center;
        // margin-left: 4px;
        input {
            width: 25px;
            height: 25px;
            font-size: 0.9rem;
        }
    }
    .select-area {
        width: 250px;
        align-self: center;
        // margin-left: 4px;
        background: #fdfce2;
        appearance: auto;
        border: solid 1px #d1cbbb;
        border-radius: 4px;
        background: #fdfce2;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }
    .select-area-jobs {
        width: 250px;
        align-self: center;
        // margin-left: 4px;
        background: #fdfce2;
        appearance: auto;
        border: solid 1px #d1cbbb;
        border-radius: 4px;
        background: #fdfce2;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }
    .select-area-question {
        width: 423px;
        align-self: center;
        // margin-left: 4px;
        background: #fdfce2;
        appearance: auto;
        border: solid 1px #d1cbbb;
        border-radius: 4px;
        background: #fdfce2;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }
    .edit-form-area {
        margin-bottom: 12px;
        height: 52px;
        .input-lastName {
            width: 214px;
            padding-right: 5px;
        }
        .input-firstName {
            width: 214px;
        }
    }
    .edit-manager-form-area {
        margin-bottom: 12px;
        height: 52px;
        .input-lastName {
            width: 214px;
            padding-right: 5px;
        }
        .input-firstName {
            width: 214px;
        }
    }
    .edit-manager-kana-form-area {
        margin-bottom: 12px;
        height: 52px;
        .input-lastName {
            width: 214px;
            padding-right: 5px;
        }
        .input-firstName {
            width: 214px;
        }
    }
    .edit-phone-form-area {
        margin-bottom: 12px;
        height: 52px;
        text-align: center;
        .input-lastName {
            width: 214px;
            padding-right: 5px;
        }
        .input-firstName {
            width: 214px;
        }
      }
      .button-margin {
        margin-right: 10px;
      }

    .contract-lastname-input {
        width: 200px;
        margin-right: 12px;
    }

    .contract-firstname-input {
        width: 200px;
    }
    .form-area {
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        width: 720px;
    }
    .button-area {
        margin-top: 12px;
        display: flex;
        justify-content: center;
    }
    .button-refresh {
        width: 190px;
        align-self: center;
        background-color: #4383e1;
    }
    .message-area {
        text-align: right;
        margin-top: 4px;
        font-size: 0.8rem;
        font-weight: bold;
        color: red;
    }

    .edit-payment-form-area {
        margin-bottom: 12px;
        height: 52px;
        .input-lastName {
            width: 214px;
            padding-right: 5px;
        }
        .input-firstName {
            width: 214px;
        }
    }
    .edit-payment-kana-form-area {
        margin-bottom: 12px;
        height: 52px;
        .input-lastName {
            width: 214px;
            padding-right: 5px;
        }
        .input-firstName {
            width: 214px;
        }
    }

    @media screen and (max-width: 768px) {
        padding: 0 30px;
        input {
            font-size: 14px;
        }
        .form-area {
            width: 350px;
        }
        .edit-form-area {
            margin-bottom: 42px;
        }
        .input-post-code {
            width: 176px;
        }
        .input-post-code {
            width: 140px;
        }
        .button-post {
            margin-left: 2px;
        }
        .button-post {
            margin-left: 10px;
        }
        .required-lable {
            padding-left: 90px;
        }
        .any-lable {
            padding-left: 90px;
        }
        .required-lable::after {
            margin-bottom: 4px;
        }
        .any-lable::after {
            margin-bottom: 4px;
        }
        .edit-form-area {
            .input-lastName {
                width: 440px;
                margin-bottom: 4px;
            }
            .input-firstName {
                width: 440px;
                margin-bottom: 4px;
            }
        }
        .edit-manager-form-area {
            height: 130px;
            .input-lastName {
                width: 440px;
                margin-bottom: 4px;
            }
            .input-firstName {
                width: 440px;
                margin-bottom: 4px;
            }
        }
        .edit-manager-kana-form-area{
            height: 130px;
            .input-lastName {
                width: 440px;
                margin-bottom: 4px;
            }
            .input-firstName {
                width: 440px;
                margin-bottom: 4px;
            }

        }
        .edit-payment-form-area{
            height: 130px;
            .input-lastName {
                width: 440px;
                margin-bottom: 4px;
            }
            .input-firstName {
                width: 440px;
                margin-bottom: 4px;
            }
        }
        .edit-payment-kana-form-area{
            height: 130px;
            .input-lastName {
                width: 440px;
                margin-bottom: 4px;
            }
            .input-firstName {
                width: 440px;
                margin-bottom: 4px;
            }

        }
        .edit-building-form-area {
            margin-top: 36px;
        }
        .select-area-jobs {
            font-size: 14px;
            width: 348px;
        }
        .select-area-question {
            font-size: 14px;
            width: 348px;
        }
        .required-lable::after {
            height: 30px;
        }
        .any-lable::after {
            height: 30px;
        }
    }
}
