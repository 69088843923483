//
// UserInfo styles
//

.UserInfo {
    width: 1008px;
    margin: 0 auto;
    padding: 30px 0;

    #tab-container {
        width: 100%;
        min-width: 1200px;
    }
    .button-area {
        margin-top: 12px;
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        #tab-container {
            width: 100%;
            min-width: 100%;
        }
        .userinfo-tab {
            display: none;
        }
    }

    // Todo Iconにしたら削除
    @media screen and (max-width: 1400px) {
        overflow-x: scroll;
    }

    .Chart {
        .form-area {
            .row {
                justify-content: space-evenly;
            }

            .edit-form-area {
                // width: 930px;
                margin-bottom: 12px;
                height: 52px;
            }

            label {
                width: 550px;
                margin-bottom: 0;
                margin-right: 12px;
                font-size: 16px;
            }

            .select-area {
                width: 230px;
                align-self: center;
                border: solid 1px #d1cbbb;
                border-radius: 4px;
                background-color: #fdfce2;
                box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
            }
            
            .label-area {
                width: 230px;
                // align-self: center;
                // border: solid 1px #d1cbbb;
                // border-radius: 4px;
                // background-color: #fdfce2;
                // box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
            }

            input {
                width: 230px;
                font-size: 16px;
                border: solid 1px #d1cbbb;
                border-radius: 4px;
                background-color: #fdfce2;
                box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
            }
        }


        @media screen and (max-width: 768px) {
            .form-area{
                .edit-form-area {
                    margin-bottom: 12px;
                    height: 100px;

                    .row {
                        justify-content: normal;
                    }

                    label {
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }
}
