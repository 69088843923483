//
// Services styles
//

.Services {
    width: 1008px;
    margin: 0 auto;
    // padding: 80px 0;

    .service-card {
        &:not(:first-child) {
            margin-top: 60px;
        }
        .service-title,
        .service-img {
            text-align: center;
        }

        img {
            height: 100%;
            //max-height: 500px;
            max-width: 800px;
            margin-left: 0px;
        }
    }
    .service-detail {
        margin-top: 40px;
    }
    #service-1 {
        .service-list {
            border-left: solid #444 1px;
            border-right: solid #444 1px;
            border-bottom: solid #444 1px;
            .item-title {
                border-right: solid #444 1px;
                padding: 20px 10px;
                background-color: #f3f3f3;
                text-align: center;
            }
            .item-body {
                padding: 20px 10px;
            }
        }
    }
    #service-2 {
        .item-box {
            padding: 30px 25px;
            border-radius: 10px;
            .title {
                font-size: 20px;
                text-align: center;
            }
            .faq-list {
                padding-left: 1.5rem;
                .item {
                    line-height: 1.5rem;
                    margin-bottom: 10px;
                }
            }
        }
    }
    #service-3 {
        .policy-title {
            margin-bottom: 20px;
            background-color: #4377c0;
            padding: 10px;
            color: $white;
            font-size: 24px;
            .main {
                text-align: right;
            }
            .sub {
                font-size: 18px;
                line-height: 2em;
            }
        }
        .policy-list {
            .title {
                height: 99px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #ccc;
                text-align: center;
                font-size: 16px;
                line-height: 1.5em;
            }
            .body {
                height: calc(100% - 99px);
                background-color: #f8f8f8;
                padding: 20px 10px;
                ul {
                    padding: 0;
                    li {
                        list-style: none;
                        line-height: 1.75em;
                    }
                }
            }
        }
    }
    #service-4 {
        .policy-menu {
            .wrapper-box {
                height: 100%;
                border: 5px solid #4377c0;
                padding: 20px;
            }
            .title {
                font-size: 18px;
                line-height: 1.3em;
            }
            .time {
                background-color: #eeeeee;
                font-size: 12px;
            }
            .description {
                padding: 10px 0;
            }
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 40px 15px;

        .service-card {
            img {
                width: 100%;
            }
        }
        #service-1 {
            .service-list {
                flex-direction: row;
                .item-title {
                    border-right: 0;
                    border-bottom: 1px solid #444;
                    font-size: 14px;
                    padding: 10px;
                }
                .item-body {
                    font-size: 14px;
                }
            }
        }
        #service-2 {
            .item-box {
                padding: 20px 10px;
                &:last-child {
                    margin-top: 10px;
                }
                .title {
                    font-size: 20px;
                }
                .faq-list {
                    font-size: 14px;
                }
            }
        }
        #service-3 {
            .policy-title {
                font-size: 20px;
                .main {
                    text-align: center;
                }
                .sub {
                    text-align: center;
                    font-size: 16px;
                }
            }
            .policy-list {
                .title {
                    height: auto;
                    padding: 10px;
                }
                .body {
                    height: auto;
                    padding: 20px 10px;
                    ul {
                        padding: 0;
                        li {
                            list-style: none;
                            font-size: 14px;
                            line-height: 1.75em;
                        }
                    }
                }
            }
        }
        #service-4 {
            .policy-menu {
                .wrapper-box {
                    padding: 20px 10px;
                }
                .title {
                    font-size: 16px;
                }
                .description {
                    font-size: 14px;
                }
            }
        }
    }
}
