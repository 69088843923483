//
// Login styles
//

.Magazine {
    // max-width: "450px";
    // margin-bottom: "10vh";
    // border-radius: ".5rem";
    // display: "flex";
    // justify-content: "center";
    // align-items: "center";
    // align-self: "center";

    input {
        width: 500px;
        font-size: 16px;
        // border: 1px solid #ccc;
        border: solid 1px #d1cbbb;
        border-radius: 4px;
        background: #fdfce2;
        //box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }
    label {
        width: 360px;
        line-height: 36px;
        margin-bottom: 0;
        //font-weight: 700;
        font-size: 14px;
        // background-color: #edebe4;
    }
    .input-mail {
        width: 500px;
        font-size: 16px;
        // border: 1px solid #ccc;
        border: solid 1px #d1cbbb;
        border-radius: 4px;
        background: #fdfce2;
        //box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }
    .lel-password {
        width: 150px;
        font-size: 14px;
        text-align: left;
    }
    .label-forgot-password {
        width: 500px;
        font-size: 14px;
        line-height: inherit;
        // text-align: right;
    }
    .edit-roboter-form-area {
        margin-top: 30px;
        height: 100px;
        max-width: 800px;
    }
    .edit-lable-form-area {
        //margin-top: 30px;
        height: 100%;
        max-width: 500px;
    }
    .edit-form-area {
        //margin-top: 30px;
        max-width: 500px;
    }
    .edit-forgot-form-area {
        //margin-top: 30px;
        height: 70px;
        max-width: 500px;
    }
    .edit-phone-form-area {
        //margin-top: 30px;
        height: 52px;
        max-width: 500px;
    }
    .button-margin {
        margin-right: 10px;
    }

    .label-manager {
        width: 224px;
        line-height: 36px;
        margin-bottom: 0;
        //font-weight: 700;
        font-size: 16px;
        // background-color: #edebe4;
    }

    .edit-form-area {
        // margin-bottom: 12px;
        height: 50px;
    }
    .form-area {
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        width: 500px;
    }
    .button-area {
        margin-top: 14px;
        display: flex;
        justify-content: center;
    }
    .button-size {
        width: 200px;
    }
    .button-size-2 {
        width: 150px;
    }
    .button-margin {
        margin-left: 4px;
    }
    .check-inline {
        display: inline-block;
        margin-right: 8px;
    }
    .required-lable {
        // width: 40px;
        margin-right: 12px;
        margin-bottom: 0;
        //font-weight: 700;
    }
    .required-lable::after {
        font-size: 12px;
        display: inline-block;
        // margin: 0 0 0 20px;
        // padding: 3px 10px;
        line-height: 24px;
        content: "必須";
        color: #fff;
        border-radius: 0;
        background: #dd821a;

        width: 34px;
        text-align: center;
        margin-bottom: 4px;
    }
    .any-lable {
        width: 40px;
        margin-right: 12px;
        margin-bottom: 0;
        font-weight: 700;
    }
    .any-lable::after {
        font-size: 12px;
        display: inline-block;
        // margin: 0 0 0 20px;
        // padding: 3px 10px;
        line-height: 28px;
        content: "任意";
        color: #fff;
        border-radius: 0;
        background: #7a9fee;

        width: 34px;
        text-align: center;
    }
    .checkbox-area {
        width: 30px;
        height: 30px;
        align-self: center;
        // margin-left: 4px;
        input {
            width: 25px;
            height: 25px;
            font-size: 0.9rem;
        }
    }
    .margin-top {
        margin-top: 10px !important;
    }
    @media screen and (max-width: 768px) {
        padding: 0 30px;
        input {
            font-size: 14px;
        }
        .form-area {
            width: 350px;
        }
        .input-mail {
            width: 356px;
        }
        label {
            width: 280px;
            font-size: 12px;
        }
    }
}
