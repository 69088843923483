.upload-button-div {
    background-color: #0d6efd;
    color: white;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    border-radius: 6px;
    border-color: #0d6efd;
    height: 38px;
}

.upload-button-div:hover {
    background-color: #0b5ed7;
    border-color: #0b5ed7;
}

.upload-button-div:focus {
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, .5);
}

.upload-button-disabled {
    pointer-events: none;
    background-color: #0d6efd;
    color: white;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    border-radius: 6px;
    border-color: #0d6efd;
    opacity: .65;
    height: 38px;
}