.article-list {
  .article-box {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 0;
    .thumbnail {
      width: 100%;
      height: 180px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .article-body {
      padding: 20px 10px 10px 10px;
      .article-title {
        font-size: 16px;
        color: #333;
      }
      .source-name {
        color: #777777;
        font-size: 14px;
      }
      .date {
        color: #777777;
        font-size: 13px;
      }
      .link-arrow {
        text-align: right;
      }
    }
  }

  .disabled {
    background-color: #d3d3d3;
    cursor: default;
  }
}

@media screen and (max-width: 768px) {
  .article-list {
    .article-box {
      .article-body {
        .article-title {
          font-size: 14px;
        }
        .source-name {
          font-size: 12px;
        }
        .date {
          font-size: 12px;
        }
      }
    }
  }
}