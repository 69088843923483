tbody td:nth-child(n+2) {
    text-align: center;
  }
  
  th {
    text-align: center;
  }
  
  tr:first-child {
    /* border-left: 0; */
  }
  
  table {
    border-style: hidden;
  }
  
  .table > :not(:first-child) {
    border-top: none;
  }