//
// Tokusyou styles
//

.Tokusyou {
  .tokusyou-column-name {
    width: 240px;
  }

  #tokusyouTable > tbody > tr > td:first-child {
    background-color: #e9ebf5;
    vertical-align: middle;
    height: 45px;
  }
  
  #tokusyouTable > tbody > tr > td:nth-child(2) {
    text-align: left;
    padding-left: 12px;
    vertical-align: middle;
  }
  
  #tokusyouTable {
    border-color: white;
    font-size: .9rem;
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;

    img {
      width: 100%;
    }
  }
}